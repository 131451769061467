<template>
    <div id="content" class="tasks" :class="[{ detail: detail, portrait: ['portrait'].includes($mq) }, $mq]">
        <div class="column left hide-scrollbar">
            <div class="calendar-navigation-container" v-if="!detail">
                <!-- <calendar @select="selectDate" condensed :doToggle="false" :selected="date.format('YYYY-MM-DD')"></calendar> -->

                <calendar-navigation @select="selectDate" @calendarOpen="calendarState" :selected_date="date.format('YYYY-MM-DD')" :future_days="1" :past_days="pastDays"></calendar-navigation>
            </div>

            <!-- <template v-if="!['portrait'].includes($mq) && !detail"> -->
            <div class="timeframes hide-scrollbar" ref="tframeContainer">
                <div class="tframe" :class="tframeClasses(tframe, index)" v-for="(tframe, index) in timeframes" @click="toggleFrame(tframe.id)" :id="index">
                    <div class="graph chart">
                        <div class="c100" :class="['p' + tframe.score, itemClasses(tframe), { condensed: detail }]">
                            <span v-if="!detail">{{ tframe.score }}%</span>
                            <div class="slice">
                                <div class="bar"></div>
                                <div class="fill"></div>
                            </div>
                        </div>
                    </div>
                    <div class="meta">
                        <h6 class="tf-title" :class="{ condensed: detail }">
                            {{ tframe.name ? tframe.name : $t('tasks.timeframe.default.title') }}
                        </h6>

                        <div class="available" v-if="!detail">
                            <span class="hour">{{ $tc('assets.timing.from', 1) }} {{ tframe.start_hour | hour }}h </span>
                            <span class="hour">{{ $tc('assets.timing.to', 1) }} {{ tframe.end_hour | hour }}h</span>
                        </div>
                    </div>

                    <!-- <span class="resume">{{ tframe.templates.length +' '+ $tc('tasks.resume', tframe.templates.length) }}</span> -->
                </div>
            </div>
            <!-- </template> -->
        </div>

        <!-- MAIN -->
        <div v-if="!detail" class="right scrollbar" :class="{ calendarOpened: calendarIsVisible }">
            <div class="contain">
                <template v-if="checklists.length > 0">
                    <item v-for="check in checklists" :item="check" :cType="type" @sendId="openDetail" :key="check.id"></item>
                </template>
                <template v-else>
                    <EmptyPage :title="$t('empty_pages.generic_title')" :description="$t('empty_pages.checklist_description')"></EmptyPage>
                </template>
            </div>
        </div>

        <!-- DETAIL -->
        <detail v-if="detail" :gId="detail" :cType="type" @setDateTimeframe="setDateTimeframe"></detail>
    </div>
</template>

<script>
import EmptyPage from '@/components/layout/EmptyPage'
import item from '@/components/domain/tasks/item'
import detail from '@/components/domain/tasks/detail'
import calendar from '@/components/layout/Calendar'
import CalendarNavigation from '@/components/layout/CalendarNavigation.vue'

export default {
    name: 'Checklist',
    components: {
        item,
        detail,
        calendar,
        EmptyPage,
        CalendarNavigation
    },
    created() {
        this.$bar.reset()
        this.load()
    },
    data() {
        return {
            type: 1,
            route_name: 'ChecklistDetail',
            route: 'Checklist',
            detail: this.$route.params.id,
            // date: this.$route.params.propDate ? this.$route.params.propDate : moment().set({ minutes: 0, seconds: 0 }),
            // date: moment().set({ minutes: 0, seconds: 0 }),
            name: this.$route.params.name,
            yesterdaytf: false,
            pastDays: 1,
            calendarIsVisible: false
        }
    },
    computed: {
        date: {
            get() {
                return this.$route.params.propDate ? this.$route.params.propDate : moment(this.$store.getters['tasks/currentDate']) //moment().set({ minutes: 0, seconds: 0 })
            },
            set(value) {
                log('setting date --> ', value.format('YYYY-MM-DD'))
                this.$store.commit('tasks/setCurrentDate', value.format('YYYY-MM-DD'))
            }
        },
        def() {
            return this.$store.getters['tasks/getDefaults']
        },

        timeframes() {
            return this.$store.getters['tasks/getTimeframes']
        },
        currentTimeframe: {
            get() {
                log('get - currentTimeFrame: ', this.$store.getters['tasks/getCurrentTimeframe'])
                return this.$store.getters['tasks/getCurrentTimeframe']
            },
            set(val) {
                log('set - currentTimeFrame: ', val)
                this.$store.commit('tasks/setCurrentTimeframe', val)
            }
        },
        user_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },

        user() {
            var employee = this.$store.getters['employee/getEmployee'](this.user_id)
            if (employee) {
                return employee
            }
        },

        checklists() {
            var checklists = this.$store.getters['tasks/getAll']
            var aReturn = []
            var rolEmployee = this.$store.getters['employee/getRol'](this.user.rol)

            var self = this
            for (var k in checklists) {
                if (checklists[k].timeframe_id == self.currentTimeframe) {
                    if (checklists[k] && checklists[k].config != false && checklists[k].config.roles && checklists[k].config.roles.length > 0) {
                        var templateroles = checklists[k].config.roles

                        // Si el rol del usuario está en el listado del template o es nivel 8 se muestra
                        if (templateroles.includes(this.user.rol) || rolEmployee.level == 8) {
                            aReturn.push(checklists[k])
                        }
                        // SI NO TIENE NINGUN ROL SE MUESTRA
                    } else aReturn.push(checklists[k])
                }
            }

            return aReturn
        }
    },
    watch: {
        $route: 'load'
    },
    methods: {
        calendarState(show) {
            this.calendarIsVisible = show
        },
        selectDate(date, refresh = false) {
            log('selectDate ->', date, refresh)
            this.date = moment(date).set({ minutes: 0, seconds: 0 })
            this.load(refresh)
        },
        setDateTimeframe(date, timeframe) {
            // this.date = moment(date).set({ minutes: 0, seconds: 0 })
            this.date = moment(moment(date).format('YYYY-MM-DD'))
            this.currentTimeframe = timeframe
        },
        isToday() {
            return this.date.format('DD-MM-YYYY') == moment().format('DD-MM-YYYY')
        },

        openDetail(id) {
            this.$router.push({ name: this.route_name, params: { id: id } })
        },

        tframeClasses(tframe, index) {
            return {
                'alert pending': tframe.alert_pending_show,
                'alert important': tframe.alert_important_show,
                selected: this.currentTimeframe == index,
                expired: this.expiredTFrame(tframe) && this.isToday()
            }
        },

        itemClasses(tframe) {
            if (tframe.score == 100 && !tframe.alert_pending_count) {
                return 'done'
            } else if (tframe.alert_pending_count && this.expiredTFrame(tframe)) {
                if (tframe.alert_pending_show) {
                    return 'uncompleted'
                } else {
                    return 'justified'
                }
            } else {
                if (this.expiredTFrame(tframe)) {
                    return 'uncompleted'
                } else {
                    return 'progress'
                }
            }
        },

        toggleFrame(id) {
            log(id)
            if (id || id == 0) {
                this.currentTimeframe = id
            } else {
                id = this.currentTimeframe
            }

            if (this.detail) {
                this.detail = false
                this.$router.push({
                    name: this.route,
                    params: { timeframe_id: id }
                })
            }
        },

        expiredTFrame(tframe) {
            if ((!tframe.name && this.isToday()) || (!this.isToday() && !this.yesterdaytf)) {
                return false
            }

            var now = moment()
            var start = moment(this.date)
                .set('hour', tframe.start_hour)
                .set('minute', 0)
                .set('second', 0)
                .set('millisecond', 0)
            var endHour = tframe.end_hour < 24 ? tframe.end_hour : tframe.end_hour - 24
            var end = moment(this.date)
                .set('hour', endHour)
                .set('minute', 0)
                .set('second', 0)
                .set('millisecond', 0)
            if (tframe.end_hour >= 24) end.add(1, 'days')

            // console.log("Ha expirado? "+ tframe.name);
            // console.log(now.format('HH:mm DD-MM-YYYY'));
            // console.log(start.format('HH:mm DD-MM-YYYY'));
            // console.log(end.format('HH:mm DD-MM-YYYY'));
            // console.log("......");
            // console.log(!now.isBetween(start, end, "hour")+" !now.isBetween(start, end)");
            // console.log(now.isAfter(end)+" now.isAfter(end)");
            // console.log("=");
            // console.log(!now.isBetween(start, end, "hour") && now.isAfter(end));
            // console.log("-------------------------------------------------");

            return !now.isBetween(start, end, 'hour') && now.isAfter(end)
        },

        scrollTo(id) {
            if (['portrait'].includes(this.$mq)) {
                this.$refs.tframeContainer.scrollLeft = document.getElementById(id).offsetLeft - window.innerWidth / 2 + document.getElementById(id).offsetWidth / 2 + 10 // plus 10px of margin
            } else {
                var scroll = {
                    container: this.$refs.tframeContainer,
                    target: document.getElementById(id),
                    offset: -100
                }
                this.$root.scrollTo(scroll)
            }
        },

        load(refresh = true) {
            var self = this

            if (!this.$store.getters['tasks/userDateChanged']) {
                if (moment(this.date).format('DD/MM/YYYY') == moment().format('DD/MM/YYYY') && this.timeframes && Object.values(this.timeframes)[Object.keys(this.timeframes).length - 1].end_hour > 24 && Object.values(this.timeframes)[Object.keys(this.timeframes).length - 1].end_hour - 24 > parseInt(moment().format('HH'))) {
                    this.yesterdaytf = true
                    this.selectDate(
                        moment()
                            .add(-1, 'days')
                            .format('YYYY-MM-DD'),
                        true
                    )
                }
            } else {
                if (
                    moment(this.date).format('DD/MM/YYYY') ==
                        moment()
                            .add(-1, 'days')
                            .format('DD/MM/YYYY') &&
                    this.timeframes &&
                    Object.values(this.timeframes)[Object.keys(this.timeframes).length - 1].end_hour > 24 &&
                    Object.values(this.timeframes)[Object.keys(this.timeframes).length - 1].end_hour - 24 > parseInt(moment().format('HH'))
                ) {
                    this.yesterdaytf = true
                }
            }

            self.$overlay.loading()
            if (self.$route.params.id == undefined) {
                self.$store
                    .dispatch('tasks/loadAll', {
                        date: self.date.valueOf() / 1000,
                        forceUpdate: true
                    })
                    .then(function() {
                        if (refresh) {
                            self.$bar.show()
                            self.$bar.reset()

                            // Set Timeframe
                            var desired = self.$route.params.timeframe_id ? self.$route.params.timeframe_id : false
                            if (self.$route.params.timeframe_id == 0) {
                                desired = '0'
                            }

                            if (!desired) {
                                var withAlert = self.$route.params.withAlert ? self.$route.params.withAlert : false
                                for (var i in self.timeframes) {
                                    let tf = self.timeframes[i]
                                    if (tf.name) {
                                        let aux = moment(self.date)
                                            .set({
                                                hours: 0,
                                                minutes: 0,
                                                seconds: 0
                                            })
                                            .add(tf.end_hour, 'hours')

                                        if (withAlert && tf[withAlert]) {
                                            desired = tf.id
                                            break
                                        } else if (moment(moment(self.date).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm:ss')).isBefore(aux) && !self.expiredTFrame(tf)) {
                                            desired = tf.id
                                            break
                                        }
                                    }
                                }
                            }

                            if (desired && !self.detail) {
                                self.currentTimeframe = desired
                            } else if (Object.keys(self.timeframes).length >= 1 && !self.detail) {
                                self.currentTimeframe = self.timeframes[0] ? self.timeframes[0].id : self.timeframes[Object.keys(self.timeframes)[0]].id
                            }

                            if (self.currentTimeframe && !self.detail) {
                                self.scrollTo(self.currentTimeframe)
                            }

                            self.detail = false
                        }
                        self.$overlay.hide()
                    })
            } else if (self.$route.params.id != undefined) {
                self.$store
                    .dispatch('tasks/loadById', {
                        id: self.$route.params.id
                    })
                    .then(function() {
                        self.detail = self.$route.params.id

                        for (let key in self.checklists) {
                            const checklist = self.checklists[key]
                            if (self.$route.params.id == checklist.id) {
                                self.currentTimeframe = checklist.timeframe_id ? checklist.timeframe_id : 0
                            }
                        }

                        self.$bar.addAction('back', {
                            callback: function() {
                                self.toggleFrame()
                            },
                            label: self.$t('menu_aux.action.back')
                        })

                        self.$overlay.hide()
                    })
            }
        }
    },
    filters: {
        hour: function(value) {
            if (value == parseInt(value, 10)) {
                return moment('00:00', 'HH:mm')
                    .add(value, 'hours')
                    .format('HH')
            } else {
                return moment('00:00', 'HH:mm')
                    .add(value, 'hours')
                    .format('HH:mm')
            }
        }
    },
    mounted() {},
    updated() {
        this.pastDays = ['portrait'].includes(this.$mq) ? 2 : 1
    },
    updated() {
        // Delete calendar open dom
        if (this.$route.name == 'ChecklistDetail') {
            this.calendarIsVisible = false
        }
    }
}
</script>

<style lang="scss">
#content.tasks {
    $column-width: 20%;
    $column-max-width: 180px;
    // $content-width: 100%;

    > .right {
        $content-margin: 12px;

        @include border-radius($default-border-radius);
        @include default-box-shadow();
        padding-top: 25px;
        background-color: $default-bg-color !important;
        margin: $content-margin 0 0 0;
        // width: calc(100% - 340px);
        // min-width: calc(100% - #{$column-max-width}) !important;
        height: calc(100% - #{$content-margin}) !important;
        border: none;

        &.empty {
            @include display-flex();
            @include align-items();
            @include justify-content(flex-end);
            width: 100% !important;

            .message {
                @include display-inline-flex();
                @include align-items();
                @include background($image: img('alexpose_04.svg'), $size: contain, $position: right center);
                width: 100%;
                padding-right: 150px;
                height: 250px;
                max-height: 100%;
            }
        }

        .contain {
            width: 90%;
            height: 100%;
            margin: 0 auto;

            .empty {
                @include empty();
                @include background($size: 70px, $position: top center);
                padding-top: 80px;
                margin-top: calc(40% - #{$menu-top-height} - 70px);
                background-image: img('confeti_light.svg');
            }
        }
    }

    > .column.left {
        $content-space: 6px;

        width: 100%;
        // min-width: 320px;
        // max-width: 320px;
        // max-width: $column-max-width !important;
        background-color: transparent !important;
        margin-top: $alex-app-padding * 3;
        padding-bottom: 0 !important;
        height: calc(100% - (#{$alex-app-padding} * 3)) !important;

        .calendar-navigation-container {
            display: block;
            width: 100%;
            height: auto;
            padding: 10px 5px;
        }

        .timeframes {
            padding: $content-space 0 $content-space $content-space;
            height: auto;
            overflow: auto;

            .tframe {
                @include interaction();
                border-radius: $default-border-radius, 0px, 0px, $default-border-radius;
                display: block;
                width: 100%;
                height: auto;
                padding: 9px 9px;
                width: calc(100%);
                text-align: center;

                @include bgHover($default-bg-color, true, 15%);

                .graph.chart {
                    $chart-size: rem(65px);
                    float: none;
                    margin: 0;
                    padding: 0;
                    width: $chart-size;
                    height: $chart-size;
                    display: inline-block;

                    .c100 {
                        margin: 0;
                    }
                    span {
                        @include font-size(ml);
                        font-family: $conden;
                        height: $chart-size;
                        line-height: $chart-size;
                    }
                }

                .tf-title {
                    @include font-size(m);
                    @include text-ellipsis();
                    font-family: $conden-bold !important;
                    margin: 0;
                    padding: 0;
                    margin-top: 3px;
                }
                .available {
                    margin-top: 3px;
                    .hour {
                        @include font-size(sm);
                        font-family: $conden;
                    }
                }

                &.alert {
                    position: relative;

                    &:before {
                        @include background($color: $main, $size: 14px);
                        @include border-radius(3px);
                        content: '';
                        position: absolute;
                        display: block;
                        min-width: 20px;
                        width: auto;
                        height: 20px;
                        z-index: 2;
                        top: 5px;
                        left: 5px;
                    }

                    &.pending:before {
                        background-color: $warning;
                        background-image: img('exclamation_light.svg');
                    }
                    &.important:before {
                        background-color: $error;
                        background-image: img('exclamation_light.svg');
                    }
                }

                &.expired {
                    @include opacity(0.6);
                }

                &.selected {
                    @include default-box-shadow();
                    background-color: $default-bg-color;
                    @include bgHover($default-bg-color, false, 1.5%);
                }
            }
        }
    }
    &.desktop {
        .left {
            max-width: 335px !important;
        }
        .right {
            width: calc(100% - 335px);
        }
    }
    &.landscape {
        .right {
            width: calc(100% - 295px);
            min-width: calc(100% - 295px);
            max-width: calc(100% - 295px);
        }
        .left {
            max-width: 295px !important;
            width: 295px !important;
        }
    }
    &.landscape:not(.detail),
    &.desktop:not(.detail) {
        .left {
            .timeframes {
                .tframe {
                    @include display-flex();
                    @include align-content(center);
                    @include justify-content(flex-start);
                    @include align-items(center);

                    .meta {
                        padding-left: 12px;
                    }
                }
            }
        }
    }
}

// detail trick
#content.tasks.detail {
    &.landscape {
        $column-width: 20%;
        $column-max-width: 3.75rem;
        .right {
            width: calc(100% - #{$column-max-width});
            min-width: calc(100% - #{$column-max-width}) !important;
        }
        .column.left {
            max-width: $column-max-width !important;
            min-width: $column-max-width !important;
        }
    }
    &.desktop {
        $column-width: 20%;
        $column-max-width: 6.25rem;
        .right {
            width: calc(100% - #{$column-max-width});
            min-width: calc(100% - #{$column-max-width}) !important;
        }
        .column.left {
            max-width: $column-max-width !important;
            min-width: $column-max-width !important;
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
#content.portrait {
    padding: 10px;

    .column.left {
        width: 100% !important;
        max-width: 100% !important;
        margin-top: 0;
        height: auto !important;

        .timeframes {
            padding: 0;
            border-radius: 5px;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
            height: auto;

            .tframe {
                box-shadow: none;
                background-color: transparent;
                max-width: 150px;
                display: inline-block;
                padding: 5px;
                margin: 0;
                margin-right: 5px;
                height: 130px;

                .graph.chart {
                    transform: scale(0.8);
                    margin-top: -5px;
                }

                .meta {
                    height: 80px;
                    display: block;
                    width: 100%;
                }

                .tf-title {
                    @include font-size(s);
                    margin-top: -5px;
                    text-align: center;
                }
                .available {
                    margin: 0;
                    margin-top: -5px;
                    text-align: center;
                    .hour {
                        @include font-size(xs);
                    }
                }
                &.selected {
                    box-shadow: none;
                    background-color: #fff;
                    // @include bgHover($default-bg-color, false, 1.5%);
                }
            }

            @include portrait-tablet {
                .tframe {
                    height: 140px;

                    .available {
                        .hour {
                            @include font-size(s);
                            line-height: 35px;
                        }
                    }
                }
            }
        }
    }
    .right {
        width: 100% !important;
        max-width: 100% !important;
        height: auto !important;
        box-shadow: none;
        background: transparent !important;
        margin: 0;
        padding: 0 !important;
        margin-top: 15px !important;

        .contain {
            width: 100%;
            height: 100%;
            margin: 0;
        }
        &.calendarOpened {
            height: calc(100% - 580px) !important;
        }
    }
}

#content.portrait.detail {
    .timeframes {
        display: none !important;
    }
}
</style>
