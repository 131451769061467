<template>
    <div class="item slideRight" :class="[$mq]">
        <div class="list" @click="sendedId(item.id)">
            <div class="category" v-if="catSelected && catSelected.id == 'all' && categories[item.category_id]">
                <div class="ball" :style="{ backgroundColor: categories[item.category_id] ? categories[item.category_id].color : '' }"></div>
                <div class="category-name">
                    {{ categories[item.category_id] ? categories[item.category_id].name : '' }}
                </div>
            </div>
            <h6 class="name">{{ item.name }}</h6>
            <!-- <span class="subname">{{ $t('register.last_time') }}: {{ item.last_update | moment }}</span> -->
        </div>
        <div class="create" v-if="(user.password || user.app_access) && roleUserLogged.level != 8" @click="sendedAddedit(item.id)">
            <div class="add"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: { type: Object },
        catSelected: { type: undefined, default: null }
    },
    data() {
        return {}
    },
    computed: {
        user_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },
        categories() {
            var items = this.$store.getters['register/getCategories']()
            return items
        },
        user() {
            var employee = this.$store.getters['employee/getEmployee'](this.user_id)
            if (employee) {
                return employee
            }
        },
        roleUserLogged() {
            var user = this.$store.getters['loginUser/getLocalEmployee']
            var roleUserLogged = this.$store.getters['employee/getEmployee'](user)
            var levelRoleUserLogged = this.$store.getters['employee/getRol'](roleUserLogged.rol)
            return levelRoleUserLogged
        }
    },
    methods: {
        sendedId(id) {
            // console.log("sendedId");
            this.$emit('sendedId', id)
        },
        sendedAddedit(id) {
            // console.log("sendedAddedit");
            this.$emit('sendedAddedit', id)
        }
    },
    filters: {
        moment: function(date) {
            return date ? moment(date).fromNow() : '--'
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
// -----------
$add-size: rem(35px);
$item-height: rem(80px);

.item {
    @include interaction();
    @include border-radius(6px);
    @include display-flex();
    @include align-items();
    @include flex-wrap(wrap);
    background-color: #fff;
    width: calc(50% - 12px);
    height: $item-height;
    margin: 0px 0px 12px 12px;
    overflow: hidden;

    @include bgHover(#fff);

    .list {
        width: calc(100% - #{rem(70px)});
        max-height: $item-height;
        height: auto;
        padding: 6px;

        .category {
            padding-left: 8px;
            @include display-flex();
            @include align-items();
            padding-bottom: 4px;

            .ball {
                @include display-inline-flex();
                @include border-radius(100%);
                width: 14px;
                height: 14px;
                margin-right: 6px;
            }
            .category-name {
                @include font-size(s);
                color: $neutro-s60;
                font-family: $text-light;
            }
        }

        .name {
            @include font-size(m);
            margin: 0;
            font-family: $text-bold;
            color: $inactive-s90;
            width: calc(100% - #{$add-size});
            width: 100%;
            height: auto;
            max-height: calc(100% - 24px);
            display: inline-block;
        }
        .subname {
            @include font-size(s);
            height: 24px;
            width: calc(100% - #{$add-size});
            padding: 3px 6px;
            display: inline-block;
            color: $inactive-s50;
            font-family: $text-light;
        }
    }

    .create {
        width: rem(70px);
        height: 100%;
        padding: 20px 20px;
        text-align: center;

        .add {
            @include border-radius(5px);
            @include background($color: $done, $image: img('more_ffffff.svg'), $size: 25px);
            width: $add-size;
            height: $add-size;

            @include bgHover($done);
        }
    }
    &.desktop {
        .create {
            padding: 24px 20px;
        }
    }
}

@media (max-width: 1000px) {
    .item {
        width: calc(100%);
    }
}
// -----------
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
$add-size: rem(40px);

.item.portrait {
    margin: 0;
    margin-bottom: 10px;
    height: auto;
    min-height: 100px;

    .list {
        width: calc(100% - #{rem(50px)});
        max-height: none;
        .name {
            max-height: none;
            @include font-size(sm);
        }
        .subname {
            @include font-size(xs);
        }
    }
    .create {
        width: rem(50px);
        padding: 20px 0;
        position: relative;

        .add {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            margin-top: -1.25rem;
        }
    }
}
</style>
