<template>
    <div id="content" class="register" :class="[{ detail: addeditId, portrait: ['portrait'].includes($mq) }]">
        <template v-if="['portrait'].includes($mq) && !addeditId">
            <div class="header" v-if="!detailId">
                <!-- ON HIGHLIGHT -->
                <template v-if="!selectedCat">
                    <div class="icon list" v-if="Object.values(categories).length > 0" :class="{ selected: !searchBar }" @click="displayCategories()"></div>
                    <div class="icon search" v-if="Object.values(templates).length > 0" @click="searchSwitch()" :class="{ selected: searchBar }"></div>
                </template>
                <!-- ON A CATEGORY -->
                <div v-else-if="selectedCat" class="back category" @click="changeCategory()">
                    <div class="ball" :class="{ highlight: selectedCat.highlight }" :style="{ backgroundColor: selectedCat.parent_id ? (categories[selectedCat.parent_id] ? categories[selectedCat.parent_id].color : '#273151') : categories[selectedCat.id] ? categories[selectedCat.id].color : '#273151' }"></div>
                    <div class="name" :class="{ all: selectedCat == categories['all'] }">{{ selectedCat.name }}</div>
                    <div class="icon search" v-if="Object.values(templates).length > 0 && (!selectedCat || selectedCat == categories['all'])" @click.stop="searchSwitch()" :class="{ selected: searchBar }"></div>
                </div>
            </div>
            <div class="search-container" v-if="searchBar || search">
                <input ref="searchRegister" class="search" type="text" @input="searchBarInput($event)" :value="search" />
                <div v-if="search != ''" class="close" @click="cleanSearch()"></div>
            </div>
            <template v-if="(!detailId && Object.values(categories).length == 0) || (selectedCat && !detail && !detailId) || (this.searchBar && !detail && !detailId)">
                <div class="items hide-scrollbar" :class="{ searching: searchBar }">
                    <item v-for="reg in filteredTemplates" :key="reg.id" :catSelected="selectedCat" :item="reg" @sendedId="openDetail" @sendedAddedit="createRegister"></item>
                    <EmptyPage v-if="Object.values(templates).length == 0" :title="$t('empty_pages.generic_title')" :description="$t('empty_pages.record_template_description')"></EmptyPage>
                </div>
            </template>
            <template v-if="detail">
                <div class="content hide-scrollbar resume">
                    <resume @goBack="back" :hasError="errorRegister()" v-if="detailId && register && !addeditId" :register="register" @sendedAddedit="openAddedit"></resume>
                </div>
            </template>
        </template>

        <template v-else-if="['landscape', 'desktop'].includes($mq) && !addeditId">
            <!-- Landscape -->
            <div class="content hide-scrollbar" :class="{ fullWidth: Object.values(categories).length == 0 || detail }">
                <div class="search-container" v-if="!detailId && Object.values(templates).length > 0">
                    <input ref="searchBarRegister" class="search" type="text" @input="searchBarInput($event)" :value="search" />
                    <div v-if="search != ''" class="close" @click="cleanSearch()"></div>
                </div>
                <template v-if="!detailId">
                    <item v-for="reg in filteredTemplates" :catSelected="selectedCat" :item="reg" :key="reg.id" @sendedId="openDetail" @sendedAddedit="createRegister"></item>
                </template>

                <EmptyPage v-if="!detailId && Object.values(templates).length == 0" :title="$t('empty_pages.generic_title')" :description="$t('empty_pages.record_template_description')"></EmptyPage>

                <resume @goBack="back" :hasError="errorRegister()" v-if="detailId && register && !addeditId" :register="register" @sendedAddedit="openAddedit"></resume>
                <p v-if="detailId && register && !addeditId && !register" class="empty">
                    {{ $t('register.empty_templates') }}
                </p>
            </div>
        </template>

        <detail v-if="detailId && addeditId" :cType="checkType" :gId="addeditId"></detail>

        <column v-if="!loading && !((['portrait'].includes($mq) && selectedCat) || (['portrait'].includes($mq) && searchBar)) && Object.values(categories).length > 1 && !detail" :showHighLight="showHighlightCat" :gSelected="selectedCat" @changeCategory="changeCategory"></column>
    </div>
</template>

<script>
import Column from '@/components/domain/register/column.vue'
import { FORMAT } from '@/constants'

import item from '@/components/domain/register/item'
// Este detail es la tabla
import resume from '@/components/domain/register/resume'
// Este detail es el comun de cheklist para crear
import detail from '@/components/domain/register/detail'

import EmptyPage from '@/components/layout/EmptyPage'

export default {
    name: 'Register',
    props: { checkType: Number },
    components: { item, resume, detail, EmptyPage, Column },
    data() {
        return {
            detail: false,
            addedit: false,
            FORMAT: FORMAT,
            searchBar: false,
            search: '',
            timerSearch: false,
            selectedCat: false,
            loading: true,
            showHighlightCat: true
        }
    },
    computed: {
        user_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },
        user() {
            var employee = this.$store.getters['employee/getEmployee'](this.user_id)
            if (employee) {
                return employee
            }
        },

        templates() {
            var templates = { ...this.$store.getters['register/getTemplates'] }
            var result = {}
            for (var templates_index in templates) {
                var template = templates[templates_index]
                if (template && template.config != false && template.config.roles && Object.keys(template.config.roles).length > 0) {
                    var templateroles = template.config.roles
                    if (templateroles && templateroles.includes(this.user.rol)) {
                        result[templates_index] = template
                    }
                    // SI NO TIENE NINGUN ROL SE MUESTRA
                } else result[templates_index] = template
            }
            return result
        },
        filteredTemplates() {
            if (!this.loading) {
                if (this.templates) {
                    // filter templates by search
                    if (this.search != '') {
                        console.log(this.$store.getters['register/getTemplatesSearched'](this.search))
                        return this.$store.getters['register/getTemplatesSearched'](this.search)
                    }
                    // filter templates by selectedCat
                    if (this.selectedCat && this.selectedCat.id != 'all') {
                        var result = {}
                        for (var templates_index in this.templates) {
                            var template = this.templates[templates_index]
                            if (template.category_id == this.selectedCat.id) {
                                result[templates_index] = template
                            }
                        }
                        return result
                    } else {
                        return this.templates
                    }
                }
            }
        },
        register() {
            return this.$store.getters['register/getItems']
        },
        detailId: {
            get() {
                return this.detail
            },
            set(value) {
                this.detail = value
            }
        },
        addeditId: {
            get() {
                return this.addedit
            },
            set(value) {
                this.addedit = value
            }
        },
        categories() {
            var items = this.$store.getters['register/getCategories']()
            return items
        }
    },
    methods: {
        back() {
            this.detail = false
            this.$bar.reset()
            this.$router.push({ name: 'Register' })
        },
        searchBarInput(e) {
            const self = this
            if (this.timerSearch) {
                clearTimeout(this.timerSearch)
                this.timerSearch = null
            }
            this.timerSearch = setTimeout(() => {
                self.search = e.target.value
                if (self.$mq != 'portrait') {
                    self.selectedCat = self.categories['all']
                }
            }, 800)
        },
        cleanSearch() {
            this.search = ''
        },
        searchSwitch() {
            if (!this.searchBar) {
                this.searchBar = true
                this.search = ''
                this.selectedCat = this.categories['all']
                this.$nextTick(() => {
                    this.$refs.searchRegister.focus()
                })
            } else {
                this.searchBar = false
                this.search = ''
            }
        },
        displayCategories() {
            this.search = ''
            this.searchBar = false
        },
        changeCategory(category) {
            if (!category) {
                this.searchBar = false
            }
            this.selectedCat = category
            this.search = ''
        },
        load(refresh = true) {
            var self = this
            var categories = this.categories

            self.detailId = self.$route.params.id
            self.addeditId = self.$route.params.edit

            if (self.detailId && !self.addeditId) {
                self.$store.dispatch('register/loadItems', { template: self.detailId, noRefresh: self.$route.params.noRefresh }).then(function() {
                    if (Object.values(categories).length == 0) {
                        self.$store.dispatch('register/loadCategories').then(function() {
                            self.loading = false
                            self.openDetail(self.detailId)
                        })
                    } else {
                        self.loading = false
                        self.openDetail(self.detailId)
                    }
                })
            } else if (self.detailId && self.addeditId) {
                self.$store.dispatch('register/loadItems', { template: self.detailId, noRefresh: self.$route.params.noRefresh }).then(function() {
                    if (Object.values(categories).length == 0) {
                        self.$store.dispatch('register/loadCategories').then(function() {
                            self.openAddedit(self.addeditId)
                        })
                    } else {
                        self.openAddedit(self.addeditId)
                    }
                })
            } else {
                if (Object.values(categories).length == 0) {
                    self.$store.dispatch('register/loadCategories').then(function() {
                        self.loading = false
                        if (!['portrait'].includes(self.$mq)) {
                            if (!self.selectedCat) {
                                self.selectedCat = Object.values(self.categories).length > 0 ? self.categories['all'] : false
                            }
                        }
                    })
                } else {
                    self.loading = false
                    if (!['portrait'].includes(self.$mq)) {
                        if (!self.selectedCat) {
                            self.selectedCat = Object.values(self.categories).length > 0 ? self.categories['all'] : false
                        }
                    }
                }
            }
        },
        errorRegister() {
            if (this.templates.length == 0 || this.detailId == undefined) {
                return false
            }
            for (var template in this.templates) {
                var idx = this.templates[template]
                log(idx, this.detailId)
                if (idx.id == this.detailId) {
                    return false
                }
            }
            return true
        },

        currentEmployee() {
            var emp_id = this.$store.getters['loginUser/getLocalEmployee']

            if (emp_id) {
                var emp = this.$store.getters['employee/getEmployee'](emp_id)
            } else {
                var emp = this.$store.getters['loginUser/getLocalLocation']
            }

            return emp
        },

        openDetail(id) {
            var self = this

            if (self.$store.getters['hasAccess'](self.currentEmployee().id, 0)) {
                self.goToDetail(id)
            } else {
                this.$popup.login({
                    list: this.$store.getters['employee/getLoginUsers'](1),
                    callAfterAccess: function(response) {
                        if (response) {
                            self.goToDetail(id)
                            self.$popup.close()
                        }
                    }
                })
            }
        },
        goToDetail(id) {
            // console.log(" >> openDetail");
            var self = this

            if (!self.detailId) {
                // console.log("Hace la redireccion");
                self.$router.push({
                    name: 'RegisterDetail',
                    params: { id: id }
                })
            } else {
                // console.log("Continua con sus cosas de open detail");
                self.$bar.reset()

                self.$bar.addAction('back', {
                    customClass: 'back-portrait',
                    callback: function() {
                        self.back()
                    },
                    label: self.$t('menu_aux.action.back')
                })

                if (Object.values(this.register).length > 0 && (this.user.app_access || this.user.password)) {
                    self.$bar.addAction('create', {
                        customClass: 'create-portrait',
                        callback: function() {
                            self.openAddedit()
                        },
                        label: self.$t('menu_aux.action.create')
                    })
                }
            }
        },

        createRegister(id) {
            var self = this
            // console.log(" >> createRegister");

            if (self.$store.getters['hasAccess'](self.currentEmployee().id, 0)) {
                self.$router.push({
                    name: 'RegisterEdit',
                    params: { id: id, edit: 'add' }
                })
            } else {
                this.$popup.login({
                    list: this.$store.getters['employee/getLoginUsers'](1),
                    callAfterAccess: function(response) {
                        if (response) {
                            self.$router.push({
                                name: 'RegisterEdit',
                                params: { id: id, edit: 'add' }
                            })
                            self.$popup.close()
                        }
                    }
                })
            }
        },

        openAddedit(id) {
            // console.log(" >> openAddedit");
            var self = this
            self.$bar.reset()

            if ((id == self.addeditId && typeof id !== 'undefined' && id != 'add') || (typeof self.addeditId !== 'undefined' && self.addeditId != 'add')) {
                // console.log("Continua con sus cosas de ADDEDIT");
                self.$bar.reset()

                if (!self.register[id].complete_date && (this.user.password || this.user.app_access)) {
                    self.$bar.addAction('save', {
                        callback: function() {
                            // console.log("doSave");

                            // CHECK IF HAS CONNECTION
                            if (!fnCheckConnection()) {
                                self.$popup.confirm({
                                    message: self.$t('register.save_offline'),
                                    textSave: self.$t('register.wait_online'),
                                    textCancel: self.$t('register.cancel_complete'),
                                    callCancel: function(popup, store, item) {
                                        if (item == 'button') {
                                            self.$router.push({
                                                name: 'RegisterDetail',
                                                params: { id: self.detailId }
                                            })
                                        } else {
                                            popup.callSave(popup, store)
                                        }
                                    }
                                })
                            } else {
                                var validation = []
                                var oData = {
                                    cType: self.checkType,
                                    check: self.addeditId,
                                    emp: self.$store.getters['loginUser/getLocalEmployee'],
                                    date: moment().unix(),
                                    values: []
                                }

                                for (var i = 0; i < self.$children[0].$children.length; i++) {
                                    if (self.$children[0].$children[i].$options._componentTag == 'answer') {
                                        var itemTask = self.$children[0].$children[i]
                                        itemTask.hasError = false

                                        if (itemTask.mandatedValidation) {
                                            if (itemTask.mandatedValidation(itemTask.value)) {
                                                validation.push(false)
                                                itemTask.hasError = true
                                                self.$snackbar.error({
                                                    message: self.$t('register.save_error_uncomplete')
                                                })
                                            }
                                            if (typeof itemTask.aId !== 'undefined' && typeof itemTask.value !== 'undefined') {
                                                let value = itemTask.value
                                                if (itemTask.def.item.itemsImage.includes(itemTask.item.type)) {
                                                    value = JSON.stringify(value)
                                                }
                                                oData.values.push({
                                                    item_id: itemTask.aId,
                                                    value: value
                                                })
                                            }
                                        }
                                    }
                                }

                                if (validation.indexOf(false) == -1) {
                                    oData.values = JSON.stringify(oData.values)
                                    self.$overlay.loading()

                                    // console.log(oData);
                                    self.$overlay.loading()
                                    self.$store.dispatch('register/complete', oData).then(function(response) {
                                        self.$store.dispatch('register/loadTemplates', {}).then(function() {
                                            self.$overlay.hide()
                                            self.$router.push({
                                                name: 'RegisterDetail',
                                                params: { id: self.detailId }
                                            })
                                        })
                                    })
                                } else {
                                }
                            }
                        }
                    })
                }

                self.$bar.addAction('back', {
                    customClass: 'back-portrait',
                    callback: function() {
                        self.$router.push({
                            name: 'RegisterDetail',
                            params: { id: self.detailId, noRefresh: true }
                        })
                    },
                    label: self.$t('menu_aux.action.back')
                })
            } else {
                if (id && typeof id !== 'undefined' && id != 'add') {
                    self.addeditId = id
                    // console.log("ID RESULTANTE: "+self.addeditId);
                    // console.log("Hace la redireccion");
                    self.$router.push({
                        name: 'RegisterEdit',
                        params: { id: self.detailId, edit: self.addeditId }
                    })
                } else {
                    self.$overlay.loading()
                    self.$store
                        .dispatch('register/add', {
                            tpl_id: self.detailId,
                            cType: self.checkType
                        })
                        .then(function(response) {
                            // console.log(response);
                            // console.log(response.id);
                            self.$overlay.hide()
                            self.addeditId = response.id
                            // console.log("ID RESULTANTE: "+self.addeditId);
                            // console.log("Hace la redireccion");
                            self.$router.push({
                                name: 'RegisterEdit',
                                params: {
                                    id: self.detailId,
                                    edit: self.addeditId
                                }
                            })
                        })
                }
            }
        }
    },
    created() {
        // console.log("created");
        this.$bar.reset()
        this.load()
    },
    watch: {
        $route: 'load',
        $mq: function(newChange, oldChange) {
            if (newChange == 'landscape') {
                if (!this.selectedCat && !this.search) {
                    this.selectedCat = Object.values(this.categories).length > 0 ? this.categories['all'] : false
                }
            } else if (newChange == 'portrait') {
                if (this.search && this.search != '') {
                    this.searchBar = true
                    this.selectedCat = false
                }
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$snackbar.hide()
        next()
    },
    beforeDestroy() {
        this.$bar.hide()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.register {
    padding: 10px;
    padding-left: 0px;
    $column-width: 30%;
    $column-max-width: 0px;
    $content-width: 100%;

    > .content {
        $content-margin: 12px;
        float: right;

        // New style
        @include border-radius($default-border-radius);
        padding-top: 25px;
        background-color: $default-bg-color;
        width: calc(70%) !important;
        height: calc(100% - #{$content-margin}) !important;
        border: none;

        @include display-flex();
        @include flex-direction(row);
        @include flex-wrap(wrap);
        @include align-content(flex-start);

        .search-container {
            position: relative;
            width: 100%;
            margin-left: 12px;
            padding-bottom: 12px;
            .search {
                @include display-flex();
                @include align-items(center);
                @include background($image: img('search_neutro.svg'), $size: 25px, $position: left 12px center);
                @include border-radius(4px);
                @include font-size(ml);
                height: 45px;
                width: 100%;
                background-color: #fff;
                padding: 0 60px 0 45px;
                border-bottom: none;
                font-family: $text;
            }

            .close {
                @include border-radius(4px);
                @include background($image: img('close_dark.svg'), $size: 12px, $position: center center);
                width: 33px;
                height: 33px;
                position: absolute;
                top: 7px;
                right: 10px;
                background-color: $neutro-t50;
                cursor: pointer;
            }
        }

        &.empty {
            @include display-flex();
            @include align-items();
            @include justify-content(flex-end);
            padding-top: 75px;

            .message {
                @include display-inline-flex();
                @include align-items();
                @include background($image: img('alexpose_04.svg'), $size: contain, $position: right center);
                width: auto;
                padding-right: 150px;
                height: 250px;
                max-height: 100%;
                margin: calc(50vh - 200px) auto 0;
            }
        }
        &.fullWidth {
            width: 100% !important;
            min-width: 100% !important;
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
#content.register.portrait {
    overflow: hidden !important;
    padding: 10px;

    > .content {
        padding: 0px !important;
        height: 100%;
    }
    #column {
        width: 100%;
        max-width: 100%;
        margin: 0;
        background-color: initial;
        padding: 0px;
        box-shadow: none;
        border-radius: 0;
        height: calc(100% - 55px) !important;
    }

    .header {
        position: relative;
        height: 62px;
        width: 100%;
        display: flex;
        align-items: center;
        z-index: 10;

        .icon {
            @include background($image: img('list_neutro.svg'), $color: #fff, $size: 25px);
            @include border-radius(4px);
            height: 45px;
            width: 45px;
            cursor: pointer;
            position: absolute;

            &.selected {
                background-color: $main-dark;
            }

            &.list {
                left: 0px;

                &.selected {
                    @include background($image: img('list_light.svg'), $size: 25px);
                }
            }
            &.search {
                @include background($image: img('search_neutro.svg'), $size: 25px);
                right: 0px;

                &.selected {
                    @include background($image: img('search_light.svg'), $size: 25px);
                }
            }
        }

        .category {
            @include display-inline-flex();
            @include align-items(center);
            @include justify-content();

            .ball {
                @include display-inline-flex();
                @include border-radius(100%);
                width: 12px;
                height: 12px;

                &.highlight {
                    @include background($image: img('star_main.svg'), $size: 18px, $position: center -2px);
                    width: 18px;
                    height: 18px;
                }
            }
            .name {
                @include text-ellipsis();
                @include font-size(ml);
                width: calc(100% - 69px);
                font-family: $text-bold;
                padding-left: 10px;

                &.all {
                    width: calc(100% - 108px);
                }
            }
        }

        .back {
            @include border-radius(4px);
            @include justify-content(left);
            height: 40px;
            line-height: 45px;
            width: 100%;
            max-width: unset;
            @include text-ellipsis();
            font-family: $text-bold;
            color: #fff;
            cursor: pointer;

            &:before {
                @include background($image: img('left_dark.svg'), $color: #fff, $size: 20px);
                width: 40px;
                height: 40px;
                display: inline-block;
                content: ' ';
                float: left;
                margin-right: 10px;
            }
            &.file.detail {
                @include display-inline-flex();
                @include align-items(center);
                color: $neutro-s80;
                .meta {
                    @include display-inline-flex();
                    @include flex-direction(column);
                    width: calc(100% - 45px - 55px);

                    .name,
                    .updated,
                    .updated .update {
                        @include font-size(s);
                        line-height: 20px;
                        // height: 16px;
                        font-family: $text;
                    }

                    .name {
                        display: block;
                        @include text-ellipsis();
                        font-family: $text-bold;
                        overflow: hidden;
                    }

                    .updated {
                        @include display-flex();
                        .update {
                            @include display-inline-flex();
                            @include font-size(13px);
                            @include text-ellipsis();
                            color: $neutro-s60;

                            .date {
                                font-family: $text-bold;
                                color: $neutro-s60;
                            }
                        }

                        .highlight {
                            @include display-inline-flex();
                            @include background($size: 15px, $position: center center, $image: img('star_main.svg'));
                            padding-left: 20px;
                            margin-bottom: 1px;
                            font-family: $conden;
                            width: fit-content;
                        }
                    }
                }

                .btn {
                    @include font-size(16);
                    max-width: 250px;
                    height: 45px;
                    width: 45px;
                    cursor: pointer;

                    &.delete {
                        @include background($image: img('delete_ffffff.svg'), $size: 20px, $position: left 5px center);
                        background-color: $error;
                        color: $neutro-light;
                        padding-left: 30px;
                        height: 55px;
                        &:hover {
                            background-color: $error-dark;
                        }
                    }
                    &.more {
                        @include background($image: img('more_vert_primary.svg'), $size: 20px, $position: center center);
                        background-color: $default-sec-color;
                        &:hover {
                            // background-color: $neutro-t50;
                        }
                    }
                }

                .more-options {
                    border-radius: 4px;
                    background-clip: padding-box;
                    @include align-items();
                    position: absolute;
                    right: 10px;
                    bottom: -40px;
                    z-index: 20;
                    background-color: #fff;
                    display: none;
                    align-content: center;
                    height: fit-content;
                    padding: 3px;
                    height: auto;

                    &.active {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include flex-direction(column);
                    }

                    .btn {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        width: 100%;
                        height: 40px;
                        min-width: 40px;

                        &.download {
                            @include background($image: img('download_fff.svg'), $size: 25px, $position: center center);
                        }
                    }
                }
            }
        }
    }
    .items {
        overflow: scroll;
        height: calc(100% - 63px);

        &.searching {
            height: calc(100% - 63px - 45px);
        }
    }

    .content.resume {
        min-width: 100% !important;
    }
    .search-container {
        position: relative;
        width: 100%;
        margin: 0;
        padding: 0px 0px;
        padding-bottom: 10px;

        .search {
            @include display-flex();
            @include align-items(center);
            @include background($image: img('search_neutro.svg'), $size: 25px, $position: left 12px center);
            @include border-radius(4px);
            @include font-size(ml);
            height: 45px;
            width: 100%;
            background-color: #fff;
            padding: 0 60px 0 45px;
            border-bottom: none;
            font-family: $text;
        }

        .close {
            @include border-radius(4px);
            @include background($image: img('close_dark.svg'), $size: 12px, $position: center center);
            width: 33px;
            height: 33px;
            position: absolute;
            top: 7px;
            right: 20px;
            background-color: $neutro-t50;
            cursor: pointer;
        }
    }
}
</style>
