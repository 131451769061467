<template>

    <div
        class="question"
        :class="[questionClasses, $mq]"
        :data-type="item.type"
        :id="item.item_id"
        :noApply="item.apply == 0 ? 1 : 0"
    >

        <div
            class="item"
            :class="[itemClasses, $mq]"
        >

            <div
                class="answer"
                :class="answerClasses"
            >

                <div class="line">
                    <div
                        class="statement"
                        :class="{'has-image': [FORMAT.PHOTO].includes(item.type) && (value != null && Object.values(value) != 0)}"
                        @click="toggleMeta(def.item.itemsInfo.includes(item.type))"
                    >
                        <div
                            class="answer-title keep-format"
                            :class="[{'optional':!item.mandatory && def.has_mandatory && def.item.itemsTitle.indexOf(item.type) == -1}, {'title-no-description': !item.description}]"
                            v-html="itemTitle + ((item.mandatory && ![FORMAT.TITLE, FORMAT.SUBTITLE].includes(item.type)) || (![FORMAT.TITLE, FORMAT.SUBTITLE].includes(item.type) && item.mandatory) ? ' *' : '')"
                            :data-after="$t('answer.optional')"
                        ></div>
                        <div
                            v-if="item.description"
                            class="description keep-format"
                            v-html="descriptionParse(item.description)"
                        ></div>
                    </div>

                    <template v-if="item.mandatory || (!item.mandatory && item.apply != 0)">

                        <div
                            class="response"
                            :class="{'no-description': !item.description}"
                        >
                            <template v-if="canShowValue">

                                <template v-if="item.type == FORMAT.CHECK">
                                    <!-- Tipo check -->
                                    <span
                                        class="value"
                                        :class="[ value ? 'checked' : '' ]"
                                        @click="checkAccess(!value)"
                                    ></span>
                                </template>

                                <template v-if="item.type == FORMAT.YESNO">
                                    <!-- Tipo SI/NO -->
                                    <span
                                        class="value"
                                        :class="[value == 0 ? 'selected' : '']"
                                        data-value="0"
                                        @click="checkAccess(0)"
                                    ></span>
                                    <span
                                        class="value"
                                        :class="[value == 1 ? 'selected' : '']"
                                        data-value="1"
                                        @click="checkAccess(1)"
                                    ></span>
                                </template>

                                <template v-if="item.type == FORMAT.YESNONC">
                                    <!-- Tipo SI/NO/NC -->
                                    <span
                                        class="value"
                                        :class="[value == 0 ? 'selected' : '']"
                                        data-value="0"
                                        @click="checkAccess(0)"
                                    >{{ $t('answer.no') }}</span>
                                    <span
                                        class="value"
                                        :class="[value == 1 ? 'selected' : '']"
                                        data-value="1"
                                        @click="checkAccess(1)"
                                    >{{ $t('answer.yes') }}</span>
                                    <span
                                        class="value"
                                        :class="[value == 2 ? 'selected' : '']"
                                        data-value="2"
                                        @click="checkAccess(2)"
                                    >{{ $t('answer.nsnc') }}</span>
                                </template>

                                <template
                                    v-if="item.type == FORMAT.TEXT"
                                    @click="checkAccess()"
                                >
                                    <!-- Tipo texto -->
                                    <input
                                        type="text"
                                        class="value"
                                        v-model.lazy="value"
                                        :disabled="isDisabled"
                                        @focus="handleFocus($event, item.item_id)"
                                        @blur="handleBlur"
                                        maxlength="300"
                                    />
                                </template>

                                <template
                                    v-if="item.type == FORMAT.PHOTO && (!aConfig || aConfig.limit <= 1)"
                                    @click="checkAccess()"
                                >
                                    <!-- Tipo imagen -->
                                    <div
                                        class="actions-image"
                                        :class="[ value != null && (typeof value[0] != 'undefined') && !isDisabled ? 'editing' : '']"
                                    >
                                        <div
                                            class="add-file"
                                            @click="capture( $event, 'answer' )"
                                        >
                                            <input
                                                type="file"
                                                name="upload-image"
                                                class="input-file"
                                                accept="image/*"
                                                capture="camera"
                                                @change="qImageReload"
                                                :disabled="isDisabled"
                                            />
                                        </div>
                                        <template v-if="value != null && Object.values(value) != 0">

                                            <a
                                                class="btn view ico"
                                                @click="qImagePreview(0, true)"
                                            ></a>
                                            <a
                                                class="btn delete ico"
                                                @click="qImageDelete(0)"
                                            ></a>
                                        </template>
                                    </div>
                                    <div
                                        class="preview-image"
                                        :class="{'has-image': value != null && Object.values(value) != 0}"
                                        @click="qImagePreview(0)"
                                        :style="[isDisabled?{'position':'relative', 'zIndex':5}:{}]"
                                    >
                                        <img :src="value != null && (typeof value[0] != 'undefined') ? value[0] : ''">
                                    </div>
                                </template>

                                <template
                                    v-if="item.type == FORMAT.PHOTO && aConfig.limit > 1"
                                    @click="checkAccess()"
                                >
                                    <!-- Tipo imagen (GALERIA) -->

                                    <div class="add-image">
                                        <div class="actions-image">
                                            <div
                                                class="add-file"
                                                @click="capture( $event, 'answer' )"
                                            >
                                                <input
                                                    type="file"
                                                    name="upload-image"
                                                    class="input-file"
                                                    accept="image/*"
                                                    capture="camera"
                                                    @change="qImageReload"
                                                    :disabled="isDisabled"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            class="preview-image"
                                            :style="[isDisabled?{'position':'relative', 'zIndex':5}:{}]"
                                        ></div>
                                    </div>

                                    <div
                                        class="galery-list"
                                        v-if="value && value.length > 0"
                                    >
                                        <div
                                            class="galery-item"
                                            v-for="(item, index) in value"
                                        >
                                            <div class="actions-image editing">
                                                <a
                                                    class="btn delete ico"
                                                    @click="qImageDelete(index)"
                                                ></a>
                                            </div>
                                            <div
                                                class="preview-image"
                                                @click="qImagePreview(index, true)"
                                                :style="{'position':'relative', 'zIndex':5}"
                                            >
                                                <img
                                                    class="galery-img"
                                                    :src="value[index] ? value[index] : ''"
                                                >
                                            </div>
                                        </div>

                                    </div>

                                </template>

                                <template v-if="item.type == FORMAT.NUMBER">
                                    <!-- Tipo numérico -->
                                    <div
                                        class="value"
                                        @click="checkAccess()"
                                        :class="[{ 'showTap':!value && value != '-'}, {'no-value': value == '-' }]"
                                    >
                                        {{value ? ( value != '-' ? value : $t('answer.no_value') ) : '' }}
                                    </div>
                                </template>

                                <template
                                    v-if="item.type == FORMAT.DATE"
                                    @click="checkAccess()"
                                >
                                    <!-- Tipo fecha -->
                                    <input
                                        @click="$event.target.showPicker()"
                                        type="date"
                                        class="value"
                                        v-model.lazy="value"
                                        :disabled="isDisabled"
                                    />
                                </template>

                                <template
                                    v-if="item.type == FORMAT.TEXTAREA"
                                    @click="checkAccess()"
                                >
                                    <!-- Tipo textarea -->
                                    <textarea
                                        class="value"
                                        v-model.lazy="value"
                                        :disabled="isDisabled"
                                        @focus="handleFocus($event, item.item_id)"
                                        @blur="handleBlur"
                                    ></textarea>
                                </template>

                                <template v-if="item.type == FORMAT.MULTIPLE">
                                    <!-- Tipo Multiple -->
                                    <select
                                        class="value"
                                        :class="{'showTap':!value}"
                                        v-model.lazy="value"
                                        @click="checkAccess()"
                                    >
                                        <option
                                            v-for="(value, index) in item.config.values"
                                            :key="index"
                                            :disabled="isDisabled"
                                        >{{value}}</option>
                                    </select>
                                </template>

                                <template
                                    v-if="item.type == FORMAT.TIME"
                                    @click="checkAccess()"
                                >
                                    <!-- Tipo hora -->
                                    <input
                                        type="time"
                                        class="value"
                                        v-model.lazy="value"
                                        :disabled="isDisabled"
                                    />
                                </template>

                                <template
                                    v-if="item.type == FORMAT.DATETIME"
                                    @click="checkAccess()"
                                >
                                    <!-- Tipo fecha y hora -->
                                    <input
                                        @click="$event.target.showPicker()"
                                        type="datetime-local"
                                        class="value"
                                        v-model.lazy="value"
                                        :disabled="isDisabled"
                                    />
                                </template>

                                <template v-if="item.type == FORMAT.SIGNATURE">
                                    <!-- Tipo firma -->
                                    <div
                                        class="signed"
                                        v-show="value"
                                        @click="qSignaturePreview()"
                                    >
                                        <div
                                            class="ico"
                                            :style="{backgroundImage: 'url('+value+')'}"
                                        ></div>
                                        <div
                                            class="text"
                                            v-if="item.config.require_emp"
                                        >{{completeEmployee.name}}</div>
                                    </div>
                                    <div
                                        class="value unsigned"
                                        @click="checkAccess()"
                                    ></div>
                                </template>

                                <template v-if="item.type == FORMAT.TEMPERATURE">
                                    <!-- Tipo temperature -->
                                    <div
                                        class="value"
                                        @click="checkAccess()"
                                        :class="[{ 'showTap':!value && value != '-'}, {'no-value': value == '-' }]"
                                    >
                                        {{value ? ( value != '-' ? value + ' ' + item.config.units : $t('answer.no_value') ) : '' }}
                                    </div>
                                </template>

                                <template v-if="item.type == FORMAT.EMAIL">
                                    <!-- Tipo enviar correo -->
                                    <button
                                        v-if="value == (1 || true)"
                                        class="value sended"
                                    >{{ $t('answer.sended_mail') }}</button>
                                    <button
                                        v-if="value == (0 || false || null)"
                                        class="value"
                                        @click="checkAccess()"
                                    >{{ $t('answer.send_mail') }}</button>

                                    <!-- Solo test -->
                                    <!-- <button class="value" @click="checkAccess()">{{ $t('answer.send_mail') }}</button> -->
                                </template>

                                <template v-if="item.type == FORMAT.DOCUMENT">
                                    <!-- Tipo documento -->
                                    <div
                                        class="value-content keep-format"
                                        v-html="item.config.message"
                                    ></div>
                                    <div
                                        class="conditions"
                                        v-if="item.config.statement || item.config.check"
                                    >
                                        <span
                                            v-if="item.config.statement"
                                            class="text-check"
                                            v-html="item.config.statement"
                                        ></span>
                                        <span
                                            v-if="item.config.check"
                                            class="value"
                                            :class="[ value ? 'checked' : '' ]"
                                            @click="qDocument(!value)"
                                        ></span>
                                    </div>
                                </template>

                                <template v-if="item.type == FORMAT.MESSAGE">
                                    <!-- Tipo mensaje -->
                                    <div
                                        class="value-content keep-format"
                                        v-html="item.config.message"
                                    ></div>
                                </template>

                                <template v-if="item.type == FORMAT.LINK">
                                    <!-- Tipo enlace -->
                                    <span
                                        class="value"
                                        @click="checkAccess()"
                                    ></span>
                                </template>

                                <template v-if="item.type == FORMAT.RESOURCE">
                                    <!-- Tipo recurso de libreria -->
                                    <span
                                        class="value"
                                        @click="qResource()"
                                    ></span>
                                </template>
                            </template>

                            <template v-else-if="item.planned_to_date">
                                <div
                                    class="postposed"
                                    @click="postpone"
                                >{{ $t('answer.postpone.postposed') }}</div>
                            </template>

                            <template v-else-if="![FORMAT.TITLE, FORMAT.SUBTITLE].includes(item.type)">
                                <template v-if="item.type == FORMAT.DOCUMENT">
                                    <!-- Tipo documento -->
                                    <div
                                        class="value-content keep-format"
                                        v-html="item.config.message"
                                    ></div>
                                    <div
                                        class="conditions"
                                        v-if="item.config.statement || item.config.check"
                                    >
                                        <div class="unknown">{{ $t('answer.unknown') }}</div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="unknown">{{ $t('answer.unknown') }}</div>
                                </template>
                            </template>

                        </div>

                    </template>

                    <template v-else-if="!item.mandatory && item.apply == 0">
                        <div class="response">
                            <div class="responseNoApply">
                                <div
                                    class="text"
                                    @click="toggleMeta(false)"
                                >{{$t('answer.actions.no_apply')}}</div>
                            </div>
                        </div>
                    </template>

                </div>
                <template v-if="!item.planned_to_date && def.item.can_review">
                    <v-btn
                        v-if="!completed && item.require_review && canBeEdited"
                        class="btn ico action review disabled"
                        @click="cantValidateAnswer"
                    >
                        <v-icon>how_to_reg</v-icon><span class="text">{{ $t('answer.actions.validate') }}</span>
                    </v-btn>
                    <v-btn
                        v-if="completed && !isReview && item.require_review && canBeEdited"
                        class="btn ico action review"
                        @click="validateAnswer"
                    >
                        <v-icon>how_to_reg</v-icon><span class="text">{{ $t('answer.actions.validate') }}</span>
                    </v-btn>
                    <v-btn
                        v-if="completed && isReview && item.require_review && canBeEdited"
                        class="btn ico action unreview"
                        @click="unvalidateAnswer"
                    >
                        <span class="text">{{ $t('answer.reviewed') }}</span>
                        <v-icon>replay</v-icon>
                    </v-btn>
                </template>

            </div>

            <button
                class="btn ico action toggleMeta"
                :class="expanded?'open':''"
                @click="toggleMeta(false)"
                :style="[canPostpone?{top:'26px'}:{top:'8px'}]"
            ></button>

            <div
                class="meta"
                v-if="expanded && def.item.itemsTitle.indexOf(item.type) == -1"
            >
                <div
                    v-if="enabledMeta"
                    class="info"
                >
                    <div
                        class="sign"
                        v-if="!itemsExcluded && !item.planned_to_date"
                    >
                        <span class="name">{{ $t('answer.performed_by') }}</span>
                        <span
                            class="bold"
                            v-if="completeEmployee"
                        >{{completeEmployee.name}}</span>
                        <span
                            class="datetime"
                            v-if="completeEmployee"
                        >{{completeDate}}</span>
                        <span
                            class="bold"
                            v-else
                        >-</span>
                    </div>
                    <div
                        class="review"
                        v-if="item.require_review && !itemsExcluded && !item.planned_to_date"
                    >
                        <span class="name">{{ $t('answer.reviewed_by') }}</span>
                        <span
                            class="bold"
                            v-if="isReview"
                        >{{isReview.employee.name}}</span>
                        <span
                            class="datetime"
                            v-if="isReview"
                        >{{isReview.complete_date.format('HH:mm DD/MM/YYYY')}}</span>
                        <span
                            class="bold"
                            v-else
                        >-</span>
                    </div>

                    <div
                        class="timing"
                        v-if="list.def.item.has_periodicity && !itemsExcluded"
                    >

                        <!-- If planned -->
                        <div class="line">
                            <!-- Posposed task to another day (this appears like "posposed" and cannot be done) -->
                            <template v-if="item.planned_to_date">
                                <span class="date-info">
                                    {{ $t('answer.postpone.planned_to', getFormattedPostponedDate(item.planned_to_date) ) }}
                                </span>
                            </template>

                            <!-- Posposed task from another day (This one has to be done) -->
                            <template v-else-if="item.planned_from_date">
                                <span
                                    v-if="item.planned_from_date"
                                    class="date-info"
                                >
                                    {{ $t('answer.postpone.planned_from', getFormattedPostponedDate(item.planned_from_date)) }}
                                </span>
                            </template>
                        </div>

                        <!-- Reasons -->
                        <div
                            class="line"
                            v-if="item.planned_to_date || item.planned_from_date"
                        >
                            <span class="bold">{{ $t('answer.postpone.reason') }}:</span>
                            <span class="reason">
                                <template v-if="item.planned_reason_msg">{{item.planned_reason_msg}}</template>
                                <template v-else-if="item.planned_reason_id">{{ getReasonName }}</template>
                            </span>
                        </div>

                        <!-- If it can be posposed or not -->
                        <div class="line">
                            <!-- Yes -->
                            <template v-if="canPostpone">
                                <span class="name">
                                    {{ $tc('answer.postpone.before_label', (item.available > 1? 2:1) ) }}
                                </span>
                                <span class="bold">
                                    {{ $tc('answer.postpone.before_date', (item.available == 1 ? 1:2), {s: item.available}) }}
                                </span>
                            </template>

                            <!-- No -->
                            <template v-else-if="!item.planned_to_date">
                                <span class="name">{{ $t('answer.postpone.cannot') }}</span>
                            </template>

                        </div>

                    </div>
                </div>

                <div class="actions">
                    <!-- <button v-if="cType != 3 && !isFuture" class="btn ico action share" @click="shareAnswer" >{{ $t('checklist.share.action') }}</button> -->
                    <button
                        v-if="item.resource && ![FORMAT.RESOURCE].includes(item.type)"
                        class="btn ico action hasFile"
                        @click="qResource"
                    >{{ $t('library.resource') }}</button>
                    <button
                        class="btn ico action comment"
                        :class="[commenting?'editing':'', (item.message || item.image)?'has':'']"
                        @click="inputComment"
                    >{{ $t('answer.actions.note') }}</button>
                    <button
                        class="btn ico action postpone"
                        v-if="list.def.item.has_periodicity && !itemsExcluded && canPostpone"
                        :class="{'cannot' : !canPostpone}"
                        @click="postpone"
                    >{{ $t('answer.actions.postpone') }}</button>

                    <!-- No aplica -->
                    <button
                        class="btn ico action noApply"
                        v-if="!item.mandatory && item.apply != 0 && !item.value"
                        @click="apply(0)"
                    >{{ $t('answer.actions.no_apply') }}</button>

                    <!-- Realizar -->
                    <button
                        class="btn ico action apply"
                        v-if="!item.mandatory && item.apply == 0 && !item.value"
                        @click="apply(1)"
                    >{{ $t('answer.actions.make_apply') }}</button>

                </div>
            </div>

        </div>

        <div
            v-if="!(!item.message && !item.image && !commenting)"
            class="has-comment"
            :class="[{'hide' : !item.message && !item.image && !commenting}, {'editing' : commenting}]"
        >
            <div class="has-note">
                <textarea
                    v-model.lazy="comment"
                    class="note"
                    :disabled="!commenting"
                    ref="refInputComment"
                    @focus="handleFocus($event, item.item_id, true)"
                    @blur="handleBlur"
                ></textarea>
            </div>

            <div
                class="has-image"
                :class="[{'editing':image}]"
            >
                <div
                    class="preview"
                    v-if="commenting || image"
                    @click="imagePreview"
                >
                    <img
                        class="image"
                        :src="image"
                    ></span>
                </div>
                <div
                    class="add-file"
                    @click="capture( $event,'comment' )"
                >
                    <input
                        type="file"
                        name="change-image"
                        class="input-file"
                        accept="image/*"
                        capture="camera"
                        :disabled="!commenting"
                        @change="imageReload"
                    />
                </div>
                <span
                    class="btn ico delete"
                    @click="imageDelete"
                ></span>
            </div>
        </div>

        <div
            class="item-alert"
            v-if="item.alerts"
            :class="[{'has-issue':hasIssue}, item.alerts.class]"
        >
            <div class="alex"></div>
            <div class="message keep-format">
                <div
                    class="preview"
                    v-if="item.alerts.message"
                    v-html="item.alerts.preview"
                ></div>
                <div
                    class="read-more"
                    @click="viewAlert"
                >{{ getString('answer.actions.read_more') }}</div>
            </div>
        </div>

        <div
            class="reference-link"
            v-if="item.reference"
        >
            <div
                class="message keep-format"
                @click="goToReference"
            >
                <div
                    class="preview"
                    v-html="getStringVariable('answer.reference.issue', getStringIssueState(item.reference.status)) "
                ></div>
                <div class="read-more">{{ getString('answer.reference.issue_action') }}</div>
            </div>
        </div>

        <!-- apply message -->
        <Message
            v-if="showApplyMessage"
            :message="$t('answer.apply.message')"
            :type="'alert'"
            customClass="custom-alert-task"
            :hasActions="false"
        ></Message>
    </div>

</template>

<script>
import { FORMAT } from '@/constants'
import Message from '@/components/domain/ui/Message'

export default {
    props: {
        cType: { type: Number, default: 1 },
        cId: { type: String },
        aId: { type: String },
        item: { type: Object },
        list: { type: Object }
    },
    data() {
        return {
            showingClock: false,
            expanded: false,
            hasAccess: true,
            userAccess: false,
            selected: null,
            error: false,
            warning: false,
            linkedIssue: false,
            commenting: false,
            loading: false,
            FORMAT: FORMAT,
            valueExpanded: true,
            showApplyMessage: false
        }
    },
    components: {
        Message
    },
    computed: {
        typename() {
            switch (parseInt(this.list.type)) {
                case 1:
                    return 'tasks'
                case 2:
                    return 'audit'
                case 3:
                    return 'register'
                case 4:
                    return 'issues'
                default:
                    return 'checklist'
            }
        },

        def() {
            return this.list.def
        },

        hasError: {
            get() {
                return this.error
            },
            set(value) {
                this.error = value
            }
        },
        hasAlert: {
            get() {
                return this.warning
            },
            set(value) {
                this.warning = value
            }
        },
        hasIssue() {
            return this.item.alerts ? !!(this.item.alerts.issues && this.item.alerts.issues.length) : false
        },
        autosave() {
            return this.def.is_autosave
        },

        completeEmployee() {
            var employee = this.item.employee

            if (typeof employee === 'object') {
                return employee
            } else if (employee) {
                return this.$store.getters['employee/getEmployee'](employee)
            } else {
                return false
            }
        },
        completeDate() {
            return this.item.complete_date ? moment(this.item.complete_date).format('HH:mm DD/MM/YYYY') : false
        },
        loggedUser() {
            return this.userAccess ? this.userAccess : this.$store.getters['loginUser/getLocalEmployee']
        },

        // Si tiene tiempo limitado, tendrá distintas "fases"
        isIntime() {
            if (this.def.has_timing) {
                if (!this.itemsExcluded) {
                    return this.list.state == 'intime'
                }
            } else {
                return true
            }
        },
        isPast() {
            if (this.def.has_timing) {
                if (!this.itemsExcluded) {
                    return this.list.state == 'past'
                }
            } else {
                return false
            }
        },
        isFuture() {
            if (this.def.has_timing) {
                if (!this.itemsExcluded) {
                    return this.list.state == 'future'
                }
            } else {
                return false
            }
        },

        // Styles and template facilities
        itemTitle() {
            return this.item.title
        },

        uncompleted() {
            if (this.def.has_timing) {
                // return !this.canBeEdited || !this.canShowValue
                return !this.canBeEdited && !this.canShowValue
                // return !this.canShowValue
                // return !( this.def.item.itemsImage.includes(this.item.type) ? (this.value ? this.value.lenght : false) : this.value ) || (!this.canBeEdited && this.isPast);
            } else {
                return !this.value && !this.canBeEdited
            }
        },
        completed() {
            log(this.completeDate)
            if (this.completeEmployee && this.completeDate != false) {
                return true
            } else {
                if ([FORMAT.PHOTO, FORMAT.SIGNATURE].includes(this.item.type)) {
                    return !!(!(typeof this.value === 'undefined' || this.value == '' || this.value == null) && typeof this.value === 'object' && this.value.length)
                } else {
                    return !!this.value
                }
            }
        },
        enabledMeta() {
            // return this.cType != 3
            return true
        },
        getReasonName() {
            var reason = this.$store.getters['getSingleReasonPostpone'](this.item.planned_reason_id)
            return reason ? reason.name : false
        },

        questionClasses() {
            return {
                past: this.isPast,
                future: this.isFuture,
                child: this.item.parent_id,
                hidden: !this.item.is_visible,
                uncompleted: this.uncompleted,
                loading: this.loading,
                'value-collapsed': !this.valueExpanded,
                'postposed-from': this.item.planned_from_date,
                'postposed-to': this.item.planned_to_date
            }
        },
        answerClasses() {
            return {
                toReview: this.item.require_review && this.canBeEdited && !this.item.planned_to_date && this.def.item.can_review,
                galery: this.item.type == FORMAT.PHOTO && this.aConfig.limit > 1
            }
        },
        itemClasses() {
            return {
                done: this.completed,
                error: this.hasError,
                issue: this.item.alerts ? !!(this.item.alerts.issues && this.item.alerts.issues.length) : false,
                alert: this.hasAlert,
                excluded: this.def.item.itemsExcluded.includes(this.item.type)
            }
        },

        itemsExcluded() {
            return this.def.item.itemsExcluded.includes(this.item.type)
        },

        user() {
            if (this.loggedUser) {
                return this.$store.getters['employee/getEmployee'](this.loggedUser)
            }
            return false
        },
        isDisabled() {
            if (this.loggedUser) {
                if (this.user) {
                    if (!this.user.password && !this.user.app_access && (this.cType == 2 || this.cType == 1)) {
                        return true
                    }
                }
                if (this.def.has_timing) {
                    let now = moment().set({ s: 0, ms: 0 })
                    let start = moment(this.list.limit.start)
                    let end = moment(this.list.limit.end)

                    if (!now.isBetween(start, end) && now.isAfter(end)) {
                        // if ( !now.isBetween(start, end) && parseInt(this.list.score) < 100 ) {
                        return true
                    } else {
                        return false
                    }
                    // } else if( this.list.type == 2 && ( this.list.complete_date || moment(this.list.start_date).valueOf() > moment().valueOf() ) ){
                } else if (this.list.type == 3 && this.list.complete_date) {
                    // validate edits dates
                    let currentTemplate = this.$store.getters['register/getTemplateById'](this.$route.params.id)
                    if (currentTemplate.config && typeof currentTemplate.config.edit_days !== 'undefined') {
                        let edit_days = parseInt(currentTemplate.config.edit_days)

                        // by default
                        if (edit_days == -1) {
                            return true
                        } else {
                            let completed_date = moment(this.list.complete_date).startOf('day')
                            let compare_date = moment()
                                .startOf('day')
                                .subtract(edit_days, 'days')
                            let days_difference = completed_date.diff(compare_date, 'days')
                            log('daysDifference', days_difference)

                            if (days_difference < 0) {
                                return true
                            }
                        }
                    } else {
                        // default
                        return true
                    }

                    // Registers
                    return false
                } else if (this.list.type == 2 && (this.list.complete_date || (this.isPast && this.def.can_edit.inPast) || (this.isFuture && this.def.can_edit.inFuture))) {
                    // Audits
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }
        },
        aConfig() {
            log('item', this.item.title, this.item.config, this.item.config && Object.keys(this.item.config).length > 0 ? this.item.config : false)
            return this.item.config && Object.keys(this.item.config).length > 0 ? this.item.config : false
        },

        canShowValue() {
            let result = false

            if (!this.item.planned_to_date) {
                if (this.def.item.itemsVisibles.includes(this.item.type)) {
                    result = true
                } else if (this.def.item.itemsExcluded.includes(this.item.type)) {
                    result = true
                } else if (this.def.item.itemsImage.includes(this.item.type) && (this.canBeEdited || (this.value ? this.value.length > 0 : false))) {
                    result = true
                } else if (!this.def.item.itemsImage.includes(this.item.type) && (this.canBeEdited || this.value)) {
                    result = true
                }
            }
            return result
        },
        canPostpone() {
            if (this.def.item.can_pospone) {
                let maxDate = moment(this.list.business_date).add(parseInt(this.item.available), 'days')
                let currentDate = moment(this.list.business_date)

                if (this.item.margin > 0 && this.item.available > 0 && currentDate.isBefore(maxDate) && this.def.item.has_periodicity && !this.item.planned_to_date && !this.value) {
                    return true
                } else {
                    return false
                }
            }
        },
        canBeEdited() {
            // Lo se, parece estupido pero hace mas legible el codigo xD
            return !this.isDisabled
        },

        // Comp values para las notas propias de ESTA tarea
        comment: {
            get() {
                return this.item.message ? this.item.message : ''
            },
            set(value) {
                let self = this
                this.$store.dispatch(self.typename + '/comment', { check: this.cId, answer: this.aId, message: value }).then(function() {
                    self.commenting = false
                })
            }
        },
        image: {
            get() {
                return this.item.image
            },
            set(value) {
                let self = this
                this.$store.dispatch(self.typename + '/comment', { check: this.cId, answer: this.aId, message: this.comment, file: value }).then(function() {
                    self.commenting = false
                })
            }
        },

        // Valor propio de la tarea
        value: {
            get() {
                if (this.autosave) {
                    if (this.def.item.itemsImage.includes(this.item.type)) {
                        let aux = this.item.value
                        if (!aux) {
                            return []
                        } else {
                            return aux
                        }
                    } else {
                        return this.item.value
                    }
                } else {
                    // TODO tiene sentido dividir el get por autosave?
                    return this.item.value
                }
            },
            set(value) {
                // console.log("set value");
                var self = this
                self.hasError = false

                const timezone = this.$store.getters['getTimeZoneName']
                let date = false

                try {
                    const options = {
                        timeZone: timezone,
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                    }
                    const dateTimeFormat = new Intl.DateTimeFormat([], options).formatToParts()
                    const objectDate = {}
                    for (const item of dateTimeFormat) {
                        objectDate[item.type] = item.value
                    }
                    const dateString = `${objectDate['year']}-${objectDate['month'].padStart(2, '0')}-${objectDate['day'].padStart(2, '0')} ${objectDate['hour'].padStart(2, '0')}:${objectDate['minute'].padStart(2, '0')}`
                    date = moment(dateString).unix()
                } catch (error) {
                    date = moment().unix()
                    logError(error)
                }

                if (this.item.type == FORMAT.CHECK) {
                    value = value ? 1 : 0
                }

                if (!this.isDisabled) {
                    var employee = this.loggedUser

                    log('this.autosave -> ', this.autosave)

                    if (this.autosave) {
                        // console.log(" - Goooo");

                        // si son de "tipo texto", cuando no tengan valor se autoresetearan (para hacer bien el recount)
                        if ([FORMAT.TEXT, FORMAT.DATE, FORMAT.TEXTAREA, FORMAT.TIME, FORMAT.DATETIME].includes(this.item.type)) {
                            // TODO
                            this.loading = true
                            this.$store.dispatch(self.typename + '/answer', { cType: this.cType, check: this.cId, answer: this.aId, emp: employee, date: date, value: value }).then(function(response) {
                                self.loading = false
                                if (!response.status) {
                                    self.$snackbar.error({ closeable: true, duration: 5000, message: self.$t('tasks.answer_offline') })
                                } else {
                                    if (self.value == '') {
                                        self.resetAnswer()
                                    } else {
                                        self.hasAccess = !self.item.require_pin
                                        self.userAccess = false
                                    }
                                    self.viewAlert()
                                }
                            })
                        } else {
                            // TODO
                            this.loading = true
                            this.$store.dispatch(self.typename + '/answer', { cType: this.cType, check: this.cId, answer: this.aId, answer_type: this.item.type, emp: employee, date: date, value: value }).then(function(response) {
                                self.loading = false
                                if (!response.status) {
                                    self.$snackbar.error({ closeable: true, duration: 5000, message: self.$t('tasks.answer_offline') })
                                } else {
                                    self.hasAccess = !self.item.require_pin
                                    self.userAccess = false
                                    self.viewAlert()
                                }
                            })
                        }
                    } else {
                        // console.log(" - Goooo");
                        if (self.mandatedValidation(value)) {
                            self.hasError = true
                        }

                        this.loading = true
                        this.$store.dispatch(self.typename + '/answer', { cType: this.cType, check: this.cId, answer: this.aId, answer_type: this.item.type, emp: employee, date: date, value: value }).then(function(response) {
                            self.loading = false
                            if (response && !response.status) {
                                self.$snackbar.error({ closeable: true, duration: 5000, message: self.$t('tasks.answer_offline') })
                            } else {
                                self.viewAlert()
                                self.hasAccess = !self.item.require_pin
                                self.userAccess = false
                            }
                        })
                    }
                }
            }
        },
        isReview() {
            let result = this.item.review
            if (result) {
                result.complete_date = moment(result.complete_date)
            }
            return result
        }
    },
    methods: {
        descriptionParse(msg) {
            return msgParse(msg)
        },
        getString(str) {
            return this.$t(str)
        },
        getStringObject(str, obj) {
            return i18n.t(str, obj)
        },

        getStringVariable(str, name) {
            return this.$t(str, { s: name })
        },

        getStringIssueState(id) {
            let issueState = this.$store.getters['getSingleIssueStates'](id)
            return issueState ? issueState.name.toLowerCase() : ''
        },

        getFormattedPostponedDate(date) {
            return { s1: moment(date).format('dddd'), s2: moment(date).format('DD'), s3: moment(date).format('MMMM') }
        },

        handleFocus(event, id, atElement) {
            atElement = typeof atElement === 'undefined' ? false : atElement

            var scroll = {
                container: this.$parent.$refs.container,
                target: document.getElementById(id),
                offset: -105
            }
            if (atElement) {
                var parentHeight = scroll.target.offsetHeight
                var elHeight = event.target.offsetHeight
                scroll.offset = elHeight
                // scroll.offset = parentHeight - elHeight;
            }
            this.$root.handleFocusKeyboard(this, scroll)
        },
        handleBlur(e) {
            this.$root.handleBlurKeyboard(this)
        },

        // Valida si se puede o no realizar una accion
        checkAccess(val) {
            var self = this
            var type = this.item.type
            // console.log("--------------------------------");
            log('checkAccess')
            log(' - type: ' + type)
            log(' - val: ' + val)

            if (this.isDisabled) {
                if (this.isPast && !this.def.can_edit.inPast) {
                    this.$popup.alert({ message: this.$t('answer.timing.cannot_past') })
                } else if (this.isFuture && !this.def.can_edit.inFuture) {
                    this.$popup.alert({ message: this.$t('answer.timing.cannot_future') })
                }
            } else if (this.item.require_pin && this.loggedUser && !this.isReview && this.canBeEdited) {
                if (!this.hasAccess) {
                    // console.log(" - abre popup check access");
                    this.$popup.access({
                        list: this.$store.getters['employee/getAccessUsers'](0),
                        callAfterAccess: function(response) {
                            if (response) {
                                // console.log(" - response de popup");

                                if (response.status) {
                                    self.hasAccess = true
                                    self.userAccess = response.emp_id
                                    self.$popup.close()

                                    if (type == 1) self.qCheck(val)
                                    if (type == 2 || type == 3) self.qOption(val)
                                    if (type == 6) self.qNumeric()
                                    if (type == 14) self.qSignature()
                                    if (type == 15) self.qTemperature()
                                    if (type == 16) self.qSendMail()
                                    if (type == 1) self.qDocument(val)
                                    if (type == 19) self.qLink()
                                }
                            }
                        }
                    })
                } else {
                    // console.log(" - ya tiene acceso");
                    if (type == 1) self.qCheck(val)
                    if (type == 2 || type == 3) self.qOption(val)
                    if (type == 6) self.qNumeric()
                    if (type == 14) self.qSignature()
                    if (type == 15) self.qTemperature()
                    if (type == 16) self.qSendMail()
                    if (type == 1) self.qDocument(val)
                    if (type == 19) self.qLink()
                }
            } else if (!this.item.require_pin && this.loggedUser && this.canBeEdited) {
                // console.log(" - No requiere pin");

                this.hasAccess = true
                if (type == 1) {
                    this.qCheck(val)
                } else if (type == 2 || type == 3) {
                    this.qOption(val)
                } else if (type == 6) {
                    this.qNumeric()
                } else if (type == 14) {
                    this.qSignature()
                } else if (type == 15) {
                    this.qTemperature()
                } else if (type == 16) {
                    this.qSendMail()
                } else if (type == 1) {
                    this.qDocument(val)
                } else if (type == 19) {
                    this.qLink()
                } else {
                    return true
                }
            } else if (!this.loggedUser && !this.isReview && this.canBeEdited) {
                // console.log(" - sin loguear");
                this.$popup.login({
                    list: this.$store.getters['employee/getLoginUsers'](1),
                    callAfterAccess: function(response) {
                        if (response) {
                            if (!self.hasAccess) {
                                self.checkAccess()
                            } else {
                                self.$popup.close()
                            }
                        }
                    }
                })
            } else {
                // console.log(" - ELSE ");
                return true
            }
        },

        qCheck(newVal) {
            if (!this.isDisabled) {
                if (newVal) {
                    this.value = newVal
                } else {
                    this.resetAnswer()
                }
            }
        },
        qOption(newVal) {
            if (!this.isDisabled) {
                if (newVal != this.value) {
                    this.value = newVal
                } else {
                    this.resetAnswer()
                }
            }
        },
        qImagePreview(pos, force = false) {
            if ((this.isDisabled && typeof this.value[pos] !== 'undefined' && this.value[pos] != false) || force) {
                this.$popup.media({ image: this.value[pos] })
            }
        },
        qImageDelete(pos) {
            if (!this.isDisabled) {
                let self = this

                this.$popup.confirm({
                    message: self.$t('popup.delete.title'),
                    textSave: self.$t('popup.delete.text_save'),
                    textCancel: self.$t('popup.delete.text_cancel'),
                    callSave: function() {
                        if (self.value && self.value.length <= 1) {
                            self.resetAnswer()
                        } else {
                            let aux = self.value
                            aux.splice(pos, 1)
                            self.value = JSON.parse(JSON.stringify(aux))
                        }
                    }
                })
            }
        },
        qImageReload(e) {
            let self = this

            if (!self.isDisabled) {
                if (!self.value || self.value.length <= 0) {
                    self.value = []
                }

                if (typeof self.aConfig.limit === 'undefined' || self.value.length < self.aConfig.limit || self.aConfig.limit == 1) {
                    var files = e.target.files || e.dataTransfer.files
                    if (!files.length) return
                    var image = new Image()
                    var reader = new FileReader()

                    reader.onload = e => {
                        image.onload = () => {
                            let aux = self.value
                            if (self.aConfig.limit == 1) {
                                aux = []
                            }
                            aux.push(resizeImage(image))
                            self.value = JSON.parse(JSON.stringify(aux))
                        }
                        image.src = e.target.result
                    }
                    reader.readAsDataURL(files[0])
                } else {
                    self.$popup.alert({ message: self.$t('answer.galery.message') })
                }
            }
        },
        capture(e, type) {
            if (process.env.VUE_APP_IS_APP == 'TRUE') {
                e.preventDefault()
                e.stopPropagation()
                var self = this

                var cameraOptions = {
                    destinationType: 0,
                    quality: 50,
                    targetWidth: 1200,
                    targetHeight: 1200,
                    correctOrientation: true
                }

                this.$popup.confirmUpload({
                    message: this.$t('cordova.plugins.camera.title'),
                    textSaveStart: this.$t('cordova.plugins.camera.text_camera'),
                    textSave: this.$t('cordova.plugins.camera.text_library'),
                    callSaveStart: function() {
                        navigator.camera.getPicture(
                            function(imageData) {
                                imageData = 'data:image/jpeg;base64,' + imageData
                                var bgImg = new Image()

                                bgImg.onload = function() {
                                    switch (type) {
                                        // TODO revisar
                                        case 'answer':
                                            let aux = self.value
                                            if (!aux) {
                                                aux = []
                                            }
                                            aux.push(bgImg.src)
                                            self.value = JSON.parse(JSON.stringify(aux))
                                            // self.value.push(bgImg.src);
                                            break
                                        case 'comment':
                                            self.image = bgImg.src
                                            break
                                    }
                                }
                                bgImg.src = imageData
                            },
                            function(message) {
                                // console.log( message );
                            },
                            cameraOptions
                        )
                    },
                    callSave: function() {
                        cameraOptions.sourceType = 0

                        navigator.camera.getPicture(
                            function(imageData) {
                                imageData = 'data:image/jpeg;base64,' + imageData
                                var bgImg = new Image()
                                bgImg.onload = function() {
                                    switch (type) {
                                        // TODO revisar
                                        case 'answer':
                                            let aux = self.value
                                            if (!aux) {
                                                aux = []
                                            }
                                            aux.push(bgImg.src)
                                            self.value = JSON.parse(JSON.stringify(aux))
                                            // self.value.push(bgImg.src);
                                            break
                                        case 'comment':
                                            self.image = bgImg.src
                                            break
                                    }
                                }
                                bgImg.src = imageData
                            },
                            function(message) {
                                // console.log( message );
                            },
                            cameraOptions
                        )
                    }
                })
            }
        },

        qNumeric() {
            if (!this.isDisabled) {
                var thisAns = this
                var decimal = thisAns.aConfig ? (thisAns.aConfig.decimal ? thisAns.aConfig.decimal : false) : false

                thisAns.$popup.numeric({
                    decimal: decimal,
                    inputVal: thisAns.value && thisAns.value != '-' ? thisAns.value : '',
                    callSave: function(self, store) {
                        if (self.inputVal == '-') {
                            thisAns.value = '-'
                        } else if (self.inputVal != '' && typeof self.inputVal !== 'undefined') {
                            thisAns.value = self.inputVal
                        } else {
                            thisAns.resetAnswer()
                        }
                    }
                })
            }
        },
        qTemperature() {
            if (!this.isDisabled) {
                var thisAns = this

                thisAns.$popup.temperature({
                    tempOptions: {
                        mesure: thisAns.aConfig.units ? thisAns.aConfig.units : 'ºC'
                    },
                    inputVal: thisAns.value && thisAns.value != '-' ? thisAns.value : '',
                    callSave: function(self, store) {
                        if (self.inputVal == '-') {
                            thisAns.value = '-'
                        } else if (self.inputVal != '' && typeof self.inputVal !== 'undefined') {
                            thisAns.value = self.inputVal
                        } else {
                            thisAns.resetAnswer()
                        }

                        // var returned = parseInt(self.inputVal.replace("-", ""));
                        // if( !isNaN(returned) ){
                        // 	thisAns.value = self.inputVal;
                        // } else {
                        // 	thisAns.resetAnswer();
                        // }
                    }
                })
            }
        },
        qSignature() {
            if (!this.isDisabled) {
                var thisAns = this
                if (this.item.config.require_emp) {
                    this.$popup.sign({
                        list: this.$store.getters['employee/getAllEmployees'],
                        selectedEl: this.loggedUser,
                        callSave: function(self2, store) {
                            if (self2.inputVal != '') {
                                thisAns.userAccess = self2.selectedEl
                                thisAns.value = self2.inputVal
                            } else {
                                thisAns.resetAnswer()
                            }
                        }
                    })
                } else {
                    this.$popup.freeSign({
                        callSave: function(self2, store) {
                            if (self2.inputVal != '') {
                                thisAns.value = self2.inputVal
                            } else {
                                thisAns.resetAnswer()
                            }
                        }
                    })
                }
            }
        },
        qSignaturePreview() {
            if (this.value) {
                this.$popup.media({ image: this.value })
            }
        },
        qDocument(newVal) {
            if (!this.isDisabled) {
                if (newVal) {
                    this.value = newVal
                } else {
                    this.resetAnswer()
                }
            }
        },
        qSendMail() {
            if (!this.isDisabled) {
                this.value = 1
                this.$snackbar.success({ message: this.$t('answer.sended_mail'), duration: 5000, closeable: true })
            }
        },
        qLink() {
            window.open(this.item.config.url)
        },

        qResource() {
            this.$popup.resource({
                resource: this.item.resource
            })
        },

        resetAnswer() {
            var self = this

            if (this.isReview != false && this.item.require_pin_review) {
                this.unvalidateAnswer()
                // this.unvalidateAnswer(true);
            } else {
                if (this.autosave) {
                    this.dispatchReset()
                } else {
                    if (self.item.mandatory && self.def.has_mandatory) {
                        this.hasError = true
                    }
                    this.dispatchReset()
                }
                this.unvalidateAnswer()
            }
        },
        dispatchReset() {
            let self = this
            // TODO
            this.loading = true
            this.$store.dispatch(self.typename + '/reset', { cType: this.cType, check: this.cId, answer: this.aId }).then(function() {
                self.loading = false
                self.hasAccess = !self.item.require_pin
                self.userAccess = false
                self.hasError = false
            })
        },

        cantValidateAnswer() {
            if (this.def.item.can_review) {
                this.$popup.alert({ message: this.$t('popup.review.cant_until') })
            }
        },
        validateAnswer() {
            if (this.def.item.can_review) {
                var self = this

                if (self.canBeEdited && !self.isReview) {
                    if (self.item.require_pin_review) {
                        self.$popup.access({
                            list: self.$store.getters['employee/getAccessUsers'](1),
                            callAfterAccess: function(response) {
                                if (response) {
                                    if (response.status) {
                                        self.$store.dispatch(self.typename + '/validate', { cType: self.cType, check: self.cId, answer: self.aId, emp: response.emp_id, date: moment().unix() }).then(function() {})
                                        self.$popup.close()
                                    }
                                }
                            }
                        })
                    } else {
                        self.$store.dispatch(self.typename + '/validate', { cType: self.cType, check: self.cId, answer: self.aId, emp: self.loggedUser, date: moment().unix() }).then(function() {})
                    }
                }
            }
        },
        unvalidateAnswer(thenReset = false) {
            if (this.def.item.can_review) {
                var self = this

                if (this.canBeEdited && this.isReview) {
                    if (this.item.require_pin_review) {
                        this.$popup.access({
                            list: this.$store.getters['employee/getAccessUsers'](1),
                            callAfterAccess: function(response) {
                                if (response) {
                                    if (response.status) {
                                        self.$store.dispatch(self.typename + '/unvalidate', { cType: self.cType, check: self.cId, answer: self.aId }).then(function() {
                                            if (thenReset) {
                                                // self.dispatchReset();
                                            } else {
                                            }
                                        })
                                        self.$popup.close()
                                    }
                                }
                            }
                        })
                    } else {
                        this.$store.dispatch(self.typename + '/unvalidate', { cType: this.cType, check: this.cId, answer: this.aId }).then(function() {
                            if (thenReset) {
                                // self.dispatchReset();
                            } else {
                            }
                        })
                    }
                }
            }
        },

        mandatedValidation(value) {
            if (this.item.mandatory && this.def.has_mandatory && (typeof value === 'undefined' || value === '' || value == null) && !this.autosave && !this.def.item.itemsExcluded.includes(this.item.type) && this.item.is_visible) {
                return true
            } else {
                return false
            }
        },

        imagePreview() {
            if (!this.commenting && this.image) {
                this.$popup.media({ image: this.image })
            } else {
                // this.$popup.media({ image: this.image })
                this.capture()
            }
        },
        imageDelete() {
            let self = this

            this.$popup.confirm({
                message: self.$t('popup.delete.title'),
                textSave: self.$t('popup.delete.text_save'),
                textCancel: self.$t('popup.delete.text_cancel'),
                callSave: function() {
                    self.image = ''
                }
            })
        },
        imageReload(e) {
            if (this.commenting) {
                var files = e.target.files || e.dataTransfer.files
                if (!files.length) return

                var image = new Image()
                var reader = new FileReader()
                var self = this

                reader.onload = e => {
                    self.image = e.target.result
                }
                reader.readAsDataURL(files[0])
            }
        },

        inputComment() {
            this.commenting = !this.commenting
            this.$nextTick(() => this.$refs.refInputComment.focus())
        },

        toggleMeta(expandValue) {
            if (expandValue) {
                this.valueExpanded = !this.valueExpanded
            } else {
                this.expanded = !this.expanded
            }
        },

        postpone() {
            if (this.canPostpone) {
                let thisAns = this
                let start = ''
                let end = ''
                let today = moment().format('YYYY-MM-DD')
                let data = {
                    selectedDate: '',
                    showReason: false,
                    showErrorReason: false,
                    errorReason: thisAns.$t('answer.postpone.error')
                }
                let list = thisAns.$store.getters['getReasonPostpone']

                if (thisAns.item.planned_from_date) {
                    start = moment(thisAns.item.planned_from_date)
                } else {
                    start = moment(thisAns.list.business_date, 'YYYY-MM-DD')
                }
                end = moment(start)
                    .add(thisAns.item.margin, 'days')
                    .format('YYYY-MM-DD')
                let start_calendar = moment(thisAns.list.business_date, 'YYYY-MM-DD')
                    .add(1, 'days')
                    .format('YYYY-MM-DD')
                data.selectedDate = start_calendar
                data.available = this.item.available ? this.item.available : ''

                thisAns.$popup.postpone({
                    title: thisAns.$t('popup.postpone.title'),
                    dateStart: start_calendar,
                    dateEnd: end,
                    textSave: this.$t('popup.default.text_save'),
                    textCancel: this.$t('popup.default.text_cancel'),
                    data: data,
                    list: list,
                    callSave: function(self, store) {
                        if (!self.selected || (list[self.selected].id == 0 && self.message.trim() == '')) {
                            self.data.showErrorReason = true
                        } else {
                            thisAns.$overlay.loading()
                            self.data.showErrorReason = false

                            store.dispatch(thisAns.typename + '/postpone', { cType: thisAns.cType, check: thisAns.cId, answer: thisAns.aId, date: self.data.selectedDate, reason: self.selected, description: self.message }).then(function(response) {
                                thisAns.$popup.close()
                                thisAns.$overlay.hide()
                                thisAns.$snackbar.info({ message: thisAns.$t('tooltip.done'), closeable: true })
                                // thisAns.$router.go();
                            })
                        }
                    }
                })
            } else {
                this.$popup.alert({
                    title: this.$t('popup.postpone.title'),
                    message: this.item.planned_to_date ? this.$t('answer.postpone.planned_to', this.getFormattedPostponedDate(this.item.planned_to_date)) : this.$t('answer.postpone.cannot'),
                    showingError: !this.canPostpone
                })
            }
        },

        openFile() {
            this.$popup.library({ file: this.item.resource })
        },

        viewAlert() {
            var thisAns = this
            if (this.item.alerts) {
                let alerts = this.item.alerts
                let issues = thisAns.hasIssue && thisAns.item.reference
                let issue_id = false

                log(alerts.class)

                if (alerts.issues.length > 0) {
                    log(this.list.type)
                    thisAns.$popup.issueAlert({
                        mood: alerts.class,
                        message: alerts.message,
                        list: { ...thisAns.$store.getters['issues/getTemplates'] },
                        selectedEl: alerts.issues[0],
                        showingError: true,
                        selected: issues,
                        showSaveStart: parseInt(this.list.type) != 3,
                        textSave: issues ? thisAns.$t('popup.issue_alert.text_redirect') : thisAns.$t('popup.issue_alert.text_save'),
                        callSave: function(pSelf, pStore) {
                            if (issues) {
                                log(issues)
                                thisAns.goToReference()
                                return Promise.resolve(pSelf, pStore)
                            } else {
                                log(pSelf)
                                if (typeof pSelf.selectedEl !== 'undefined') {
                                    log('entra if', pSelf.selectedEl)
                                    return pStore
                                        .dispatch(thisAns.typename + '/addIssue', {
                                            tpl_id: pSelf.selectedEl,
                                            cType: 4,
                                            checklist_ref: thisAns.list.id,
                                            item_ref: thisAns.item.item_id
                                        })
                                        .then(function(response) {
                                            if (response) {
                                                // TODO REVIEW por que esta esto aqui?
                                                log(response)
                                                // pStore.dispatch(thisAns.typename + '/loadById', { id: thisAns.list.id })

                                                issue_id = Object.keys(response)[0]
                                                return Promise.resolve(pSelf, pStore)
                                            }
                                        })
                                } else {
                                    return Promise.resolve(pSelf, pStore)
                                }
                            }
                        },
                        callSaveStart: function(pSelf, pStore) {
                            log(issue_id)
                            thisAns.$router.push({ name: 'IssuesDetail', params: { id: issue_id } })
                            return Promise.resolve(pSelf, pStore)
                        }
                    })
                } else if (alerts.message != '') {
                    this.$popup.alert({
                        mood: alerts.class,
                        message: alerts.message
                    })
                }
            }
        },

        goToReference() {
            if (this.item.reference) {
                this.$router.push({
                    name: 'IssuesDetail',
                    params: { id: this.item.reference.checklist, checkType: this.item.reference.type }
                })
            }
        },

        /**
         * @param {Number} apply (0 => no apply, 1 => apply)
         */
        apply(apply) {
            const self = this
            const employee = this.loggedUser

            if (apply == 0) {
                self.$snackbar.hide()
                self.$popup.confirm({
                    message: self.$t('answer.apply.remember_no_apply_text', { username: self.user.name, task: self.item.title }),
                    textSave: self.$t('popup.confirm.not_apply_text_save'),
                    textCancel: self.$t('popup.confirm.not_apply_text_cancel'),
                    callSave: function() {
                        self.$overlay.loading()
                        self.$store
                            .dispatch(self.typename + '/applyrecurrent', { cType: self.cType, check: self.cId, answer: self.aId, emp: employee, date: moment().unix(), apply: apply })
                            .then(function(response) {
                                self.$popup.close()
                                self.$overlay.hide()
                                self.showApplyMessage = true
                                self.toggleMeta(false)
                            })
                            .catch(error => {
                                logError(error)
                                self.$overlay.hide()
                            })
                    },
                    callCancel: function() {
                        self.$overlay.loading()
                        self.$store
                            .dispatch(self.typename + '/apply', { cType: self.cType, check: self.cId, answer: self.aId, emp: employee, date: moment().unix(), apply: apply })
                            .then(function(response) {
                                self.$popup.close()
                                self.$overlay.hide()
                                self.showApplyMessage = true
                                self.toggleMeta(false)
                            })
                            .catch(error => {
                                logError(error)
                                self.$overlay.hide()
                            })
                    }
                })
            } else {
                self.$popup.confirm({
                    message: self.$t('answer.apply.remember_apply_text', { username: self.user.name, task: self.item.title }),
                    textSave: self.$t('popup.confirm.not_apply_text_save'),
                    textCancel: self.$t('popup.confirm.not_apply_text_cancel'),
                    callSave: function() {
                        self.$overlay.loading()
                        self.$store
                            .dispatch(self.typename + '/applyrecurrent', { cType: self.cType, check: self.cId, answer: self.aId, emp: employee, date: moment().unix(), apply: apply })
                            .then(function(response) {
                                self.$popup.close()
                                self.$overlay.hide()
                                self.showApplyMessage = false
                                self.toggleMeta(false)
                            })
                            .catch(error => {
                                logError(error)
                                self.$overlay.hide()
                            })
                    },
                    callCancel: function() {
                        self.$overlay.loading()
                        self.$store
                            .dispatch(self.typename + '/apply', { cType: self.cType, check: self.cId, answer: self.aId, emp: employee, date: moment().unix(), apply: apply })
                            .then(function(response) {
                                self.$popup.close()
                                self.$overlay.hide()
                                self.showApplyMessage = false
                                self.$snackbar.main({ message: self.$t('answer.apply.revert'), duration: 5000, closeable: true, type: 'info' })
                                self.toggleMeta(false)
                            })
                            .catch(error => {
                                logError(error)
                                self.$overlay.hide()
                            })
                    }
                })
            }
        }
    },
    mounted() {}
    // filters: {
    // 	moment: function(date, format) {
    // 		return date ? moment(date).format(format) : "";
    // 	},
    // },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.issues.detail,
#content.register.detail,
#content.audit.detail,
#content.tasks.detail {
    $ans-border: #ccc;
    $ans-bg: #fff;
    $ans-value-bg: #f3f3f3;
    $ans-text-color: #222;
    $ans-desc-color: #666;
    $meta-bg: #fbfbfb;

    $answer-size: rem(40px);

    .question {
        &:first-child {
            margin-top: 15px;
        }
        width: 100%;
        margin: 30px auto;
        padding: 0 12px 0 12px;
        position: relative;

        .unknown {
            @include border-radius(3px);
            @include font-size(sm);
            font-family: $text;
            color: $error;
            font-style: italic;
            text-align: center;
            padding: 10px 0;
            width: rem(100px);
            margin-left: rem(10px);
            display: inline-block;
            float: right;
        }

        .answer,
        .answer > .line {
            @include display-inline-flex();
            @include align-items();
            @include flex-wrap(wrap);
            width: 100%;
            min-height: $answer-size;
            padding-right: 10px;

            .statement {
                @include font-size(m);
                width: 100%;
                color: $ans-text-color;
                font-family: $text-light;

                .answer-title {
                    @include font-size(m);
                    width: 100%;
                    color: $ans-text-color;
                    font-family: $text-light;

                    b {
                        @include font-size(inherit);
                        color: inherit;
                    }
                    ul {
                        list-style: disc;
                        padding: 5px 0;
                        padding-left: 30px;
                        color: inherit;
                    }
                    li {
                        @include font-size(inherit);
                        color: inherit;
                    }

                    &.mandatory {
                        // color: $error-dark;
                        // text-decoration: underline;
                        font-family: $text;

                        &:after {
                            content: ' *';
                            // color: $error;
                        }
                    }
                    &.optional {
                        font-family: $text-light;

                        &:after {
                            @include font-size(s);
                            color: #888;
                            content: attr(data-after);
                        }
                    }
                }
                .description {
                    @include font-size(s);
                    display: block;
                    color: $ans-desc-color;
                }
            }
            .stat-description:after {
                @include font-size(s);
                content: attr(data-desc);
                display: block;
                color: $ans-desc-color;
            }

            .response {
                text-align: right;

                > div {
                    width: 100%;
                    @include display-inline-flex();
                    @include flex-wrap(wrap);
                    @include align-items(center);
                    @include justify-content(flex-end);
                    @include flex-direction(row);
                }

                .responseNoApply {
                    @include display-inline-flex();
                    @include flex-wrap(wrap);
                    @include align-items(flex-end);
                    @include justify-content(flex-end);
                    height: 100%;
                    width: 150px;
                    cursor: pointer;

                    .text {
                        @include display-inline-flex();
                        @include flex-wrap(wrap);
                        @include align-items(center);
                        @include justify-content(center);
                        @include background($color: $main-t80, $image: img('error_main-s40.svg'), $size: 16px, $position: left 9px center);
                        @include font-size(m);
                        @include border-radius(4px);
                        font-family: $conden-bold;
                        color: $main-s40;
                        padding: 9px 15px 9px 30px;
                        height: auto;
                        width: max-content;
                    }
                }

                .value {
                    @include interaction();

                    @include font-size(s);
                    @include border-radius(3px);
                    // line-height: 1;
                    font-family: $text;
                    text-transform: uppercase;
                    text-align: center;
                    width: $answer-size;
                    height: $answer-size;
                    display: inline-block;
                    border: none;

                    @include bgHover($ans-value-bg);
                }

                .postposed {
                    @include border-radius(3px);
                    @include background($size: 16px, $position: left 3px center, $color: $main);
                    @include font-size(s);
                    line-height: $answer-size;
                    font-family: $text-bold;
                    color: #fff;
                    text-align: center;
                    padding: 0;
                    width: 80px;
                    height: $answer-size;
                    display: inline-block;
                    float: right;
                }
            }
        }

        .answer {
            width: calc(100% - #{$answer-size});
            padding: 6px;
            background-color: $ans-bg;
        }

        .answer-state {
            @include border-radius(5px);
            height: 5px;
            width: 100%;
            margin: 0;
            background-color: $accent-dark;
        }
        .toReview {
            .line {
                width: calc(100% - 100px);
            }
        }

        .meta {
            min-height: 60px;
            height: auto;
            width: 100%;
            padding: 10px 0;
            background-color: $meta-bg;

            .actions,
            .info {
                float: left;
                display: inline-block;
                min-height: $answer-size;
                height: auto;
            }
            .actions {
                width: 235px;
                float: right;
                padding: 0 10px;
            }
            .info {
                width: calc(100% - 235px);

                .sign,
                .review,
                .unreview,
                .period,
                .timing {
                    display: block;
                    width: 100%;
                    padding-left: 12px;
                    padding-bottom: 6px;
                    min-height: 20px;
                    height: auto;

                    .bold,
                    .datetime,
                    .name,
                    .date-info,
                    .reason {
                        @include font-size(s);
                        color: $ans-desc-color;
                        margin-right: 3px;
                    }
                    .reason {
                    }
                    .date-info {
                        color: $main;
                        font-family: $text;
                    }
                    .bold {
                        font-family: $text;
                    }
                    .datetime {
                        @include font-size(s);
                        color: $ans-desc-color;
                    }
                    .bold,
                    .datetime {
                        .avatar {
                            @include border-radius(100%);
                            width: 15px;
                            height: 15px;
                        }
                    }
                    .ico {
                        @include border-radius(50%);
                        background-size: 20px;
                        width: 20px;
                        height: 20px;
                        position: relative;
                        bottom: -4px;
                        background-color: $main;
                        margin-right: 5px;
                    }
                }
            }
        }

        .item-alert {
            width: 100%;
            padding: 5px 0;
            margin-top: 6px;

            .alex {
                @include background($image: img('alexhead_08.svg'), $size: contain, $position: left bottom);
                width: rem(40px);
                height: rem(40px);
                display: inline-block;
                margin-right: 6px;
            }

            .message {
                @include border-radius(3px);
                @include font-size(s);
                line-height: 25px;
                font-family: $text;
                color: $main-s40;
                padding: 0 6px;
                display: inline-block;
                background-color: $main-t70;
                overflow: hidden;
                width: auto;
                max-width: calc(100% - #{rem(40px)} - #{rem(6px)});
                height: rem(40px);

                b {
                    @include font-size(s);
                    color: $main-text;
                }

                ul {
                    list-style: disc;
                    padding-left: 30px;
                }

                li {
                    color: $main-text;
                    @include font-size(s);
                }

                .preview,
                .read-more {
                    display: inline-block;
                    line-height: rem(40px);
                    font-family: $text;
                    float: left;
                }

                .preview {
                    @include text-ellipsis();
                    width: calc(100% - 80px - 12px);
                }
                .read-more {
                    @include interaction();
                    font-family: $text;
                    color: $main-text;
                    margin-left: 12px;
                    width: 80px;
                    text-align: center;
                }
            }

            &.has-issue {
                .alex {
                    background-image: img('alexhead_03.svg');
                }
                .message {
                    background-color: tint($error, 70%);
                    color: $error-dark;

                    .read-more {
                        color: $error-dark;
                    }
                }
            }

            &.info {
                // same as parent
            }
            &.warning {
                .alex {
                    background-image: img('alexhead_04.svg');
                }

                .message {
                    background-color: tint($warning, 70%);
                    color: $warning-dark;

                    .read-more {
                        color: $warning-dark;
                    }
                }
            }
            &.error {
                .alex {
                    background-image: img('alexhead_03.svg');
                }

                .message {
                    background-color: tint($error, 70%);
                    color: $error-dark;

                    .read-more {
                        color: $error-dark;
                    }
                }
            }
        }

        & > .reference-link {
            width: 100%;
            padding: 0 0 5px 66px;

            .message {
                @include background($image: img('alert_alert.svg'), $size: 18px, $position: left center, $color: transparent);
                @include border-radius(3px);
                @include font-size(s);
                line-height: 25px;
                font-family: $text;
                color: $main-s40;
                padding: 9px 12px 6px 25px;
                display: inline-block;
                overflow: hidden;
                width: auto;
                max-width: calc(100% - 6px);
                height: auto;

                .preview,
                .read-more {
                    display: inline-block;
                }

                .preview {
                }
                .read-more {
                    font-family: $text;
                    color: $error-dark;
                    margin-left: 9px;
                    text-align: center;
                }
            }
        }

        .state {
            .planned {
                width: calc(100% - 100px);
                display: inline-block;
                padding: 0 12px;

                .name,
                .date,
                .hour {
                    @include font-size(14);
                    font-family: $text;
                    color: #666;
                    text-transform: uppercase;
                    text-align: center;
                    margin-left: 3px;
                }
                .name {
                    @include font-size(xs);
                    font-family: $text-light;
                }
                .date,
                .hour {
                    // color: $main-dark;
                }
            }

            .reviewed {
                @include background($color: transparent);
                @include font-size(s);
                display: inline-block;
                float: right;
                padding: 0;
                padding-right: 6px;
                margin: 0;
                color: $ans-desc-color;
                font-style: italic;
                font-family: $text-light;
                text-align: right;
                width: 100px;
            }
        }

        .btn.action {
            color: $ans-text-color;

            &.review {
                margin: 0;
                margin-left: 10px;
                padding: 0;
                height: $answer-size;
                width: 90px;
                text-transform: none;
                box-shadow: none;
                background-color: $accent-t70;

                .v-icon,
                .text {
                    @include font-size(s);
                    color: $accent-s60;
                }
                .v-icon {
                    @include font-size(ml);
                    margin-right: 3px;
                }
                .text {
                    font-family: $text-bold;
                }

                &.disabled {
                    @include opacity(0.5);
                    @include filter(grayscale(100%));
                }
            }

            &.unreview {
                @include background($color: transparent);
                margin: 0;
                margin-left: 10px;
                padding: 0;
                height: $answer-size;
                width: 90px;
                text-transform: none;
                box-shadow: none;

                .v-icon,
                .text {
                    @include font-size(s);
                    color: #888;
                    text-align: right;
                }
                .v-icon {
                    @include font-size(sm);
                    margin-left: 3px;
                }
                .text {
                    font-style: italic;
                    font-family: $text;
                }
            }

            &.toggleMeta {
                @include background($color: transparent, $size: 18px);
                background-image: img('more_vert_aaaaaa.svg');
                width: rem(40px);
                display: inline-block;

                &.open {
                    background-image: img('more_vert_primary.svg');
                }
            }

            &.hasFile,
            &.share,
            &.comment,
            &.postpone,
            &.noApply,
            &.apply {
                @include background($color: transparent, $size: 20px, $position: top 3px center);
                @include font-size(s);
                text-align: center;
                font-family: $conden;
                color: $ans-text-color;
                width: rem(50px);
                height: rem(40px);
                float: right;
                padding: 25px 0 0 0;
                margin-left: 3px;

                @include bgHover($meta-bg);
            }
            &.postpone {
                background-image: img('postpone_aaaaaa.svg');
                &.cannot {
                    @include opacity(0.25);
                }
            }
            &.hasFile {
                background-image: img('attach_aaaaaa.svg');
            }
            &.noApply {
                background-image: img('error_neutro-s70.svg');
            }
            &.apply {
                background-image: img('reload_neutro_s70.svg');
            }
            &.share {
                background-image: img('share_aaaaaa.svg');
                background-size: 18px;
            }
            &.comment {
                background-image: img('notes_create_aaaaaa.svg');

                &.has {
                    background-image: img('notes_available_aaaaaa.svg');
                    background-size: 22px;
                }
                &.editing {
                    background-image: img('notes_create_primary.svg');
                }
            }
        }

        .item {
            @include display-inline-flex();
            @include flex-wrap(wrap);
            @include align-items();
            @include justify-content(flex-end);
            @include border-radius(3px $default-border-radius $default-border-radius 3px);
            @include height-fit-content();
            background-color: $ans-bg;
            width: 100%;
            padding: 0;
            overflow: hidden;
            margin: 0;
            transition-duration: 0.2s;
            border-left: 5px solid #ccc;
            height: auto;
            min-height: $answer-size + rem(10px);
            // border-left: 5px solid $accent;

            &.excluded {
                border-color: #ccc;
            }
            &.done {
                border-color: $done;
            }
            &.error {
                border-color: $error;
            }
            &.alert {
                border-color: $warning;
            }
            &.issue {
                border-color: $error;
            }
        }

        .has-comment {
            @include display-inline-flex();
            @include flex-wrap(wrap);
            width: 100%;
            max-height: 80px;
            height: 60px;
            position: relative;

            &.editing {
                height: 80px;
            }
            &.hide {
                display: none;
            }

            .has-note {
                $image-size: rem(40px);
                display: inline-block;
                width: calc(100% - 110px - #{$image-size});
                margin: 0 0 0 $image-size;
                padding: 12px 0 0 0;
                min-height: $image-size;
                height: 100%;
                max-height: inherit;

                .note,
                .note:focus {
                    display: inline-block;
                    width: 100%;
                    height: $image-size;
                    margin: 0;
                    background-color: $ans-bg;
                }
                .note[disabled],
                .note:focus[disabled] {
                }

                &.hide {
                    display: none;
                }
            }
            &.editing .has-note {
                .note,
                .note:focus {
                    height: 60px;
                    background-color: $ans-bg;
                }
            }

            .has-image {
                @include display-inline-flex();
                @include flex-wrap(wrap);
                @include flex-direction(column);
                width: 100px;
                height: 60px;
                text-align: center;
                margin-left: 10px;

                .preview {
                    @include border-radius(3px);
                    width: rem(40px);
                    height: rem(40px);
                    overflow: hidden;
                    border: 1px solid #eee;
                    display: block;
                    margin-top: 13px;

                    .image {
                        width: auto;
                        max-width: unset;
                        height: rem(40px);
                    }
                }

                .add-file,
                .change-image,
                .btn.delete,
                .btn.view {
                    @include border-radius(3px);
                    visibility: hidden;
                    background-color: transparent;
                    width: rem(40px);
                    height: rem(40px);
                    display: inline-block;
                }

                .input-file {
                    left: -60px !important;
                    top: 12px;
                    width: rem(40px) !important;
                    height: rem(40px) !important;
                    max-width: unset;
                }
                .btn.delete,
                .btn.view {
                    @include opacity(0);
                }

                &.hide {
                    display: none;
                }
            }
            .has-image.editing {
                .btn.delete {
                    @include opacity(0.3);
                    background-position: left 6px top 13px;
                    background-size: 18px;
                }
                .btn.view {
                    @include opacity(0.3);
                    background-position: left 6px top 13px;
                    background-size: 18px;
                }
            }
            &.editing .has-image {
                height: 80px;

                .preview {
                    @include background($image: img('camera_cccccc.svg'), $color: #fff, $size: 30px);
                    width: 60px;
                    height: 60px;
                    .image {
                        height: 60px;
                    }
                }
                .add-file,
                .change-image,
                .btn.delete,
                .btn.view {
                    visibility: visible;
                }
                .input-file {
                    width: 60px !important;
                    height: 60px !important;
                }
            }
        }

        .suggest {
            -webkit-animation-duration: 1500ms;
            animation-duration: 1500ms;
            -webkit-animation-name: attention;
            animation-name: attention;
            -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
            -webkit-animation-iteration-count: 2;
            animation-iteration-count: 2;
            -webkit-animation-direction: normal;
            animation-direction: normal;
            // transition-duration: 0.2s;
        }

        &.loading {
            $loading-color: #fff;
            animation-duration: 0.2s;

            .item {
                position: relative;
                border-color: $loading-color;

                &:after {
                    @include background($color: $loading-color, $image: img('loader_primary.svg'), $size: 50px);
                    @include opacity(0.8);
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: $loading-color;
                    width: 100%;
                    height: 100%;
                    z-index: 5;
                }
            }
        }

        &[data-type='1'] .answer {
            // CHECK

            .statement {
                max-width: calc(100% - #{rem(40px)});
            }

            .response {
                height: rem(40px);

                .value {
                    @include border-radius(100%);
                    @include background($size: 16px, $color: $ans-value-bg);
                    border: 2px solid $ans-border;
                }
                .value.checked {
                    @include background($size: cover, $image: img('checked_ok.svg'));
                    border: none;
                }
            }
        }

        &[data-type='2'] .answer {
            // YES/NO
            .statement {
                max-width: calc(100% - #{rem(100px)});
            }

            .response .value {
                @include background($color: $ans-bg);

                &[data-value='0'] {
                    background-image: img('error_dddddd.svg');
                }
                &[data-value='1'] {
                    background-image: img('checked_dddddd.svg');
                }

                &.selected {
                    &[data-value='0'] {
                        background-image: img('error_alert.svg');
                    }
                    &[data-value='1'] {
                        background-image: img('checked_ok.svg');
                    }
                }

                &:hover {
                    background-color: $ans-value-bg;
                }
            }
        }

        &[data-type='3'] .answer {
            // YES/NO/NC
            .statement {
                max-width: calc(100% - #{rem(180px)});
            }
            .value {
                background-color: $ans-value-bg;
            }
            .value.selected {
                color: #fff;
            }

            .value[data-value='0'].selected {
                background-color: $error;
            }
            .value[data-value='1'].selected {
                background-color: $done;
            }
            .value[data-value='2'] {
                width: 70px;
            }
            .value[data-value='2'].selected {
                background-color: #aaa;
            }
        }

        &[data-type='2'] .answer,
        &[data-type='3'] .answer {
            // YES/NO /NC

            .response {
                @include display-flex();

                .value {
                    @include border-radius(rem(40px));
                    margin-left: rem(10px);
                    line-height: $answer-size;
                    color: $ans-text-color;

                    &.selected {
                        color: #fff;
                    }
                }
            }
        }

        &[data-type='4'] .answer {
            // TEXT
            @include flex-wrap(wrap);

            .statement {
                max-width: calc(100%);
                display: inline-block;
            }

            .response {
                width: 100%;
                margin-top: 6px;
                padding-top: 6px;
                border-top: 1px solid #eee;

                border-top: none;
                padding-top: 0;
                margin-top: 0;

                color: $ans-text-color;

                .value {
                    @include background($image: img('input_text_cccccc.svg'), $size: 28px, $position: left 8px center);
                    @include border-radius(0);
                    background-color: $ans-value-bg;
                    width: calc(100%);
                    text-transform: none;
                    text-align: left;
                    padding: 0 0 0 44px;
                }

                .text-temp {
                    display: none;
                    margin: 0;
                    padding: 0;
                    width: 0;
                    height: 0;
                }
            }
        }

        &[data-type='5'] .answer {
            // IMAGE
            $qImage: rem(60px);
            $qActions: rem(40px);
            $qTotal: $qImage + $qActions;
            padding-right: 6px;

            .statement {
                max-width: calc(100% - #{$qTotal});
            }

            .response {
                @include display-flex();
                width: $qTotal;

                .preview-image {
                    @include interaction();
                    @include background($image: img('camera_cccccc.svg'), $color: $ans-value-bg);
                    @include border-radius(3px);
                    background-size: calc(#{$qImage} - #{rem(20px)});
                    float: left;
                    width: $qImage;
                    height: $qImage;
                    margin-top: 3px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        visibility: hidden;
                    }

                    .galery-img {
                        visibility: unset;
                    }

                    &.has-image {
                        img {
                            visibility: inherit;
                        }
                    }
                }

                .actions-image {
                    width: $qActions;
                    height: $qImage + rem(5px);
                    float: left;

                    .btn {
                        padding: 0;
                        min-height: unset;
                    }

                    .add-file {
                        width: 0;
                        height: 0;
                        // height: $qImage / 2;
                    }
                    .btn.delete {
                        @include border-radius(3px);
                        width: $qActions;
                        height: $qActions;
                        min-height: $qActions - rem(10px);
                    }
                    .btn.view {
                        @include border-radius(3px);
                        width: $qActions;
                        height: $qActions - rem(10px);
                    }
                    .btn.view + .btn.delete {
                        height: $qActions - rem(10px);
                    }

                    .input-file {
                        width: $qImage !important;
                        height: $qImage !important;
                        max-width: unset;
                        // left: $qActions;
                        background-color: $ans-value-bg;
                        top: -40px;
                    }
                }
            }

            .actions-image:not(.editing) {
                .btn.delete,
                .btn.view {
                    display: none;
                }
            }

            .actions-image.editing {
                .btn.delete {
                    @include background($image: img('delete_cccccc.svg'), $color: transparent, $size: 20px);
                }
                .btn.view {
                    @include background($image: img('eye_cccccc.svg'), $color: transparent, $size: 20px);
                    z-index: 5;
                }
            }
            &.galery {
                // IMAGE GALERY
                $qImgGalery: rem(40px);

                .statement {
                    position: relative;
                    z-index: 2;
                }

                .title-no-description {
                    position: relative;
                    top: 13px;
                }

                .response {
                    display: block;
                    width: 100%;
                    margin-top: -$qImgGalery;

                    .add-image {
                        .input-file {
                            $qActions: rem(40px);
                            $qImage: rem(40px);
                            width: $qImage !important;
                            height: $qImage !important;
                            max-width: unset;
                            left: $qActions;
                            background-color: $ans-value-bg;
                            top: 0px;
                            left: 43px;
                        }

                        .preview-image {
                            // margin-top: rem(20px);
                            background-image: img('more_cccccc.svg');
                            background-size: 20px;
                            width: $qImgGalery;
                            height: $qImgGalery;
                        }
                        .actions-image {
                            height: $qImgGalery;
                        }
                    }

                    .galery-list {
                        width: 100%;
                        text-align: right;
                        padding-top: 6px;

                        .galery-item {
                            display: inline-block;
                            margin-left: rem(12px);

                            .preview-image {
                                background: none;
                            }
                        }
                    }

                    &.no-description {
                        // margin-top: 0px;
                        .add-image {
                            margin-top: 21px;
                        }
                    }
                }
            }
        }

        &[data-type='6'] .answer {
            // NUMERIC
            padding-right: 6px;

            .statement {
                max-width: calc(100% - #{rem(100px)});
            }

            .response {
                @include display-flex();

                .value {
                    @include background($color: $ans-value-bg, $size: 25px);
                    @include font-size(ml);
                    line-height: $answer-size;
                    width: rem(100px);
                    min-height: $answer-size;
                    height: auto;
                    display: block;

                    &:focus {
                        background-color: tint($main, 95%);
                    }
                    &.showTap {
                        background-image: img('input_number_cccccc.svg');
                    }
                    &.no-value {
                        @include font-size(xs);
                        line-height: rem(16px);
                        font-family: $text;
                        background-image: none;
                        padding-top: 5px;
                    }
                }
            }
        }

        // DATE AND TIME TYPES
        &[data-type='7'] .answer,
        &[data-type='12'] .answer,
        &[data-type='13'] .answer {
            .response {
                .value {
                    @include background($size: 28px, $position: left 8px center);
                    width: 100%;
                    text-transform: none;
                    padding: 0 0 0 rem(44px);
                    text-align: left;
                    background-color: $ans-value-bg;
                }
            }
        }

        &[data-type='7'] .item {
            // DATE
            .statement {
                width: calc(100% - #{rem(200px)});
            }
            .response {
                width: rem(200px);

                .value {
                    background-image: img('input_date_cccccc.svg');
                }
            }
        }

        &[data-type='8'] {
            // TITLE
            margin: rem(60px) auto 0px auto;

            .answer-title {
                @include font-size($size: l);
                font-family: $title !important;
                color: $main-s30 !important;
                text-transform: uppercase;
            }
        }
        &[data-type='9'] {
            // SUBTITLE
            margin: rem(45px) auto 0px auto;

            .answer-title {
                @include font-size($size: ml);
                font-family: $title !important;
                color: $main-dark !important;
            }
        }

        &[data-type="8"], // TITLE &
			&[data-type="9"] {
            // SUBTITLE
            .answer {
                background-color: transparent;
            }
            .answer-title {
                background-color: transparent;
                text-align: center;
            }
            .description {
                text-align: center;
            }
            .item,
            .item.done {
                border: none;
            }

            .sign,
            .btn.action,
            .alert,
            .state,
            .meta {
                display: none;
            }

            .item {
                @include border-radius(0);
                width: 100%;
                margin: 0 auto;
                background-color: transparent;
            }

            .statement {
                max-width: unset !important;
            }
            .response,
            .line {
                padding: 0;
                width: 100% !important;
            }
        }

        &[data-type='10'] .answer {
            // TEXTAREA
            @include flex-wrap(wrap);

            .statement {
                max-width: calc(100%);
                display: inline-block;
            }

            .response {
                width: 100%;
                padding-top: 6px;

                .value {
                    @include background($image: img('input_text_cccccc.svg'), $size: 28px, $position: left 8px center);
                    @include border-radius(0);
                    background-color: $ans-value-bg;
                    width: calc(100%);
                    text-transform: none;
                    text-align: left;
                    padding: 10px 5px 10px 44px;
                    height: rem(66px);
                    border: none;
                    color: $ans-text-color;
                }

                .text-temp {
                    display: none;
                    margin: 0;
                    padding: 0;
                    width: 0;
                    height: 0;
                }
            }
        }

        &[data-type='11'] .answer {
            // MULTIPLE SELECT
            .statement {
                width: calc(100% - #{rem(200px)});
            }
            .response {
                width: rem(200px);

                .value {
                    // @include text-ellipsis();
                    @include background($color: $ans-value-bg, $size: 10px, $position: right 9px center, $image: img('down_caret_aaaaaa.svg'));
                    width: 100%;
                    padding-right: 26px;
                    &:focus {
                        background-color: tint($main, 95%);
                    }
                    // &.showTap {
                    // 	background-image: img('down_caret_aaaaaa.svg');
                    // }
                }

                option[disabled] {
                    display: none;
                }
            }
        }

        &[data-type='12'] .item {
            // HOUR
            .statement {
                width: calc(100% - #{rem(150px)});
            }
            .response {
                width: rem(150px);

                .value {
                    width: 100%;
                    text-align: center;
                    padding-left: 43px;
                    background-image: img('input_time_cccccc.svg');
                }
            }
        }

        &[data-type='13'] .answer {
            // DATETIME
            .statement {
                max-width: calc(100% - #{rem(250px)});
            }

            .response {
                width: rem(250px);

                .value {
                    @include background($image: img('input_datetime_cccccc.svg'), $size: 28px, $position: left 8px center);
                    width: 100%;
                    padding: 0 0 0 44px;
                    text-align: left;

                    &::-webkit-input-placeholder {
                        color: #ccc;
                        font-weight: lighter;
                    }

                    &::-moz-placeholder {
                        color: #ccc;
                        font-weight: lighter;
                    }

                    &:-ms-input-placeholder {
                        color: #ccc;
                        font-weight: lighter;
                    }

                    &::-ms-input-placeholder {
                        color: #ccc;
                        font-weight: lighter;
                    }

                    &::placeholder {
                        color: #ccc;
                        font-weight: lighter;
                    }
                }
            }
        }

        &[data-type='14'] .answer {
            // SIGNATURE
            padding-right: rem(6px);

            .statement {
                max-width: calc(100% - #{rem(140px)} - #{rem(6px)});
            }

            .response {
                @include display-flex();
                width: rem(146px);

                .value {
                    @include background($color: $ans-value-bg, $size: 28px);
                    @include font-size(sm);
                    width: rem(40px);
                    display: block;

                    &:focus {
                        background-color: tint($main, 95%);
                    }
                    &.unsigned {
                        margin-left: auto;
                        background-image: img('input_sign_cccccc.svg');
                    }
                }

                .signed {
                    width: rem(100px);
                    margin-right: rem(6px);

                    .text {
                        @include text-ellipsis();
                        width: 100%;
                        height: calc(100% - #{rem(50px)});
                    }
                    .ico {
                        @include background($size: cover);
                        width: 100%;
                        height: rem(50px);
                    }
                }

                .value,
                .signed {
                    display: block;
                    float: right;
                }
            }
        }

        &[data-type='15'] .answer {
            // TEMPERATURE
            padding-right: 6px;

            .statement {
                max-width: calc(100% - #{rem(90px)});
            }

            .response {
                @include display-flex();

                .value {
                    @include background($color: $ans-value-bg, $size: 25px);
                    @include font-size(ml);
                    line-height: $answer-size;
                    width: rem(90px);
                    display: block;

                    &:focus {
                        background-color: tint($main, 95%);
                    }
                    &.showTap {
                        background-image: img('input_temperature_cccccc.svg');
                    }
                    &.no-value {
                        @include font-size(xs);
                        line-height: rem(16px);
                        font-family: $text;
                        background-image: none;
                        padding-top: 5px;
                    }

                    // &:not(.showTap):not(.no-value):after {
                    //     content: '\00b0';
                    // }
                }
            }
        }

        &[data-type='16'] .answer {
            // SEND MAIL

            .statement {
                max-width: calc(100% - #{rem(150px)});
            }

            .response {
                @include display-flex();

                .value {
                    @include background($color: $ans-value-bg, $size: 25px, $image: img('send_cccccc.svg'), $position: right 6px center);
                    @include font-size(sm);
                    width: rem(150px);
                    padding-right: 30px;
                    display: block;
                    text-transform: none;
                    font-family: $text;
                    color: $ans-text-color;

                    &:focus {
                        background-color: tint($main, 95%);
                    }
                    &.sended {
                        @include background($color: transparent, $image: none);
                        padding-right: 0;
                    }
                }
            }
        }

        &[data-type="17"] .answer,  // DOCUMENT
		&[data-type="18"] .answer {
            // MESSAGE

            .statement {
                @include interaction();
                @include background($image: img('up_dark.svg'), $size: 14px, $position: right 6px center);
                max-width: calc(100%);
                display: inline-block;
                padding-right: $answer-size;
            }

            .response {
                @include flex-direction(column);
                min-height: unset;
                width: 100%;

                .value-content {
                    @include background($color: $ans-value-bg);
                    @include border-radius(0);
                    width: calc(100%);
                    text-transform: none;
                    text-align: left;
                    padding: 10px 5px 10px 44px;
                    height: auto;
                    color: $ans-text-color;
                }
            }
        }
        &[data-type="17"].value-collapsed .answer,  // DOCUMENT
		&[data-type="18"].value-collapsed .answer {
            // MESSAGE

            .statement {
                @include background($image: img('down_dark.svg'), $size: 14px, $position: right 6px center);
            }

            .response {
                .value-content {
                    height: 0;
                    display: none;
                    overflow: hidden;
                }
            }
        }
        &[data-type='17'] .answer {
            // DOCUMENT

            .response {
                @include flex-direction(column);
                padding-top: 12px;

                .conditions {
                    @include display-flex();
                    @include justify-content();
                    width: 100%;
                    height: rem(40px);
                    text-align: center;
                    margin: 12px 0 6px 0;

                    .text-check,
                    .value {
                        display: inline-block;
                        color: $ans-text-color;
                    }
                    .text-check {
                        @include font-size(sm);
                        font-family: $text;
                        line-height: rem(40px);
                        height: rem(40px);
                        max-width: calc(100% - #{rem(40px)});
                        width: auto;
                        padding-right: 12px;
                    }
                    .value {
                        @include border-radius(100%);
                        @include background($size: 16px, $color: $ans-value-bg);
                        width: rem(40px);
                        border: 2px solid $ans-border;

                        &.checked {
                            @include background($size: cover, $image: img('checked_ok.svg'));
                            border: none;
                        }
                    }
                }
            }
        }

        &[data-type='18'] .answer {
            // MESSAGE
            @include flex-wrap(wrap);

            .response {
                padding-top: 6px;
            }
        }

        &[data-type='19'] .answer {
            // LINK
            padding-right: 6px;

            .statement {
                max-width: calc(100% - #{$answer-size});
            }

            .response {
                @include display-flex();

                .value {
                    @include background($image: img('link_primary.svg'), $color: $ans-value-bg, $size: 25px);
                    @include font-size(ml);
                    line-height: $answer-size;
                    width: $answer-size;
                    display: block;

                    &:focus {
                        background-color: tint($main, 95%);
                    }
                }
            }
        }

        &[data-type='20'] {
            .item {
                border-color: $default-sec-color;
                .answer {
                    // LINK
                    padding-right: 6px;

                    .statement {
                        max-width: calc(100% - #{$answer-size});
                    }

                    .response {
                        @include display-flex();

                        .value {
                            @include background($image: img('eye_preview_dark.svg'), $color: $ans-value-bg, $size: 25px);
                            @include font-size(ml);
                            line-height: $answer-size;
                            width: $answer-size;
                            display: block;

                            &:focus {
                                background-color: tint($main, 95%);
                            }
                        }
                    }
                }
            }
        }

        &.uncompleted {
            .done {
                border-color: #ccc;
            }
            .answer {
                .statement {
                    width: calc(100% - #{rem(120px)});
                    max-width: calc(100% - #{rem(120px)});
                }
                .response {
                    width: rem(120px);
                    display: block;
                    text-align: center;
                    margin: 0;
                }
            }
        }

        &[data-type='17'].uncompleted .answer,
        &[data-type='18'].uncompleted .answer {
            // MESSAGE

            .statement {
                width: 100%;
                max-width: 100%;
            }
            .response {
                width: 100%;
            }
        }

        &.complete-all {
            margin-bottom: rem(60px);

            .item {
                border: none;
            }

            .answer {
                width: 100%;
                background-color: $main-t80;

                .line {
                    padding: 0 10px;
                }
                .statement {
                    max-width: calc(100% - #{$answer-size});

                    .answer-title {
                        color: $main-dark;
                    }
                }

                .response {
                    height: $answer-size;

                    .value {
                        @include border-radius(100%);
                        @include background($size: 16px, $color: $main-t80);
                        border: 2px solid $main-t10;

                        @include bgHover($main-t80);
                    }
                    .value.semichecked {
                        @include background($size: cover, $image: img('checked_aaaaaa.svg'));
                        border: none;
                    }
                    .value.checked {
                        @include background($size: cover, $image: img('checked_primary.svg'));
                        border: none;
                    }
                }
            }
        }

        &[noApply='1'] .answer {
            .statement {
                width: calc(100% - #{rem(150px)});
                max-width: calc(100% - #{rem(150px)});
            }
            .response {
                width: rem(150px);
                display: block;
            }
        }
    }

    .question.past,
    .question.future {
        @include filter(grayscale(25%));
        position: relative;

        .ico.lock {
            @include background($image: img('lock_aaaaaa.svg'), $size: 25px, $position: top 6px center);
            position: absolute;
            width: rem(50px);
        }

        .item {
            //border-width: 50px;
            border-color: #eee;
        }

        .response {
            @include opacity(0.8);
        }
    }

    .question.future .ico.lock {
        background-image: img('unlock_primary.svg');
    }

    .question.postposed-to {
        .statement {
            max-width: calc(100% - #{rem(80px)}) !important;
        }
        .response {
            width: rem(80px) !important;
        }

        .item {
            border-color: $info;
            // border-color: $ans-bg;

            .line {
                padding-right: 0px;

                .response {
                    .postposed {
                        width: 100%;
                    }
                }
            }
        }
    }

    .question.child {
        width: 95% !important;
        margin-top: rem(30px) !important;
        margin-bottom: rem(30px) !important;
        margin-left: 5% !important;
    }
    .question.hidden {
        display: none;
    }

    @-webkit-keyframes attention {
        0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
        25% {
            -webkit-transform: rotate(-10deg) scale(1.2);
            transform: rotate(-10deg) scale(1.2);
            @include opacity(0.9);
        }
        50% {
            -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
        }
        75% {
            -webkit-transform: rotate(10deg) scale(1.2);
            transform: rotate(10deg) scale(1.2);
            @include opacity(0.9);
        }
        100% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
    }
    @keyframes attention {
        0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
        25% {
            -webkit-transform: rotate(-10deg) scale(1.2);
            transform: rotate(-10deg) scale(1.2);
            @include opacity(0.9);
        }
        50% {
            -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
        }
        75% {
            -webkit-transform: rotate(10deg) scale(1.2);
            transform: rotate(10deg) scale(1.2);
            @include opacity(0.9);
        }
        100% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
    }
}
</style>


// PORTRAIT STYLES
<style lang="scss" >
#content.detail {
    .question.portrait {
        padding: 0 !important;
        margin: 15px 0 !important;

        &[data-type='5'] .item {
            $qImage: rem(40px);
            $qActions: rem(40px);
            $qTotal: $qImage + $qActions;

            // IMAGE
            .statement {
                max-width: calc(100% - #{$qImage} - 25px);

                &.has-image {
                    max-width: calc(100% - #{$qTotal} - 25px);
                }
            }
            .response {
                position: relative;
                width: min-content;

                .actions-image {
                    position: absolute;
                    left: right;
                    width: 50px;

                    .add-file {
                        width: 50px;
                        height: 50px;

                        input {
                            left: 0 !important;
                        }
                    }
                }

                .editing {
                    position: relative;
                    display: initial;
                    width: $qActions;

                    .input-file {
                        top: 0;
                        left: 50px;
                    }
                }

                .value {
                }
            }
            .galery {
                .add-image {
                    margin-top: 30px !important;
                }
            }
        }

        &[data-type='12'] .item {
            // HOUR
            .statement {
                width: calc(100% - #{rem(100px)});
            }
            .response {
                width: rem(100px);

                .value {
                    width: 100%;
                    text-align: center;
                    padding-left: 25px;
                    background-image: img('input_time_cccccc.svg');
                    background-size: 20px;
                    padding-top: 15px;
                }
            }
        }

        &[data-type='15'] .item {
            // TEMPERATURE
            .statement {
                width: calc(100% - #{rem(100px)});
            }
            .response {
                width: rem(100px);

                .value {
                    font-family: $text-light;
                    width: 100%;
                }
            }
        }
            // Datetime
        &[data-type='13'] .answer {
            .line {
                @include display-flex();
                @include flex-direction(row);
                width: 100% !important;
                justify-content: space-between;

                .statement {
                    width: 100%;
                    max-width: calc(100% - 220px);                
                }
    
                .response {
                    width: 220px;
    
                    .value {
                        @include background($image: img('input_datetime_cccccc.svg'), $size: 28px, $position: left 8px center);
                        width: 100%;
                        padding: 0 0 0 44px;
                        text-align: left;
    
                        &::-webkit-input-placeholder {
                            color: #ccc;
                            font-weight: lighter;
                        }
    
                        &::-moz-placeholder {
                            color: #ccc;
                            font-weight: lighter;
                        }
    
                        &:-ms-input-placeholder {
                            color: #ccc;
                            font-weight: lighter;
                        }
    
                        &::-ms-input-placeholder {
                            color: #ccc;
                            font-weight: lighter;
                        }
    
                        &::placeholder {
                            color: #ccc;
                            font-weight: lighter;
                        }
                    }
                }
            }
        }
            // date
        &[data-type='7'] .answer {
            .line {
                @include display-flex();
                @include flex-direction(row);
                width: 100% !important;
                justify-content: space-between;

                .statement {
                    width: 100%;
                    max-width: calc(100% - 180px);                
                }
    
                .response {
                    width: 180px;
    
                    .value {
                        @include background($image: img('input_datetime_cccccc.svg'), $size: 28px, $position: left 8px center);
                        width: 100%;
                        padding: 0 0 0 44px;
                        text-align: left;
    
                        &::-webkit-input-placeholder {
                            color: #ccc;
                            font-weight: lighter;
                        }
    
                        &::-moz-placeholder {
                            color: #ccc;
                            font-weight: lighter;
                        }
    
                        &:-ms-input-placeholder {
                            color: #ccc;
                            font-weight: lighter;
                        }
    
                        &::-ms-input-placeholder {
                            color: #ccc;
                            font-weight: lighter;
                        }
    
                        &::placeholder {
                            color: #ccc;
                            font-weight: lighter;
                        }
                    }
                }
            }
        }
        // SELECT
        &[data-type='11'] .answer {
            .line {
                @include display-flex();
                @include flex-direction(row);
                justify-content: space-between;
                width: 100%;

                .statement {  
                        width: calc(100% - 160px);            
                }
    
                .response {
                    width: auto;
    
                    .value {
                        @include text-ellipsis();
                        width: 160px !important;
                    }
                }
            }
        }
        // Email
        &[data-type='16'] .answer {
            .line {
                @include display-flex();
                @include flex-direction(row);
                justify-content: space-between;
                width: 100%;

                .statement {              
                }
    
                .response {
                    width: auto;
    
                    .value {
                        padding: 10px 10px;
                        padding-right: 30px;
                        background-size: 25px;
                        
                    }
                }
            }
        }

        .item.portrait {
            .answer {
                width: calc(100%) !important;
                .line {
                    padding-right: 0 !important;
                    .statement {
                        .answer-title {
                            @include font-size(s);
                            line-height: 0.875rem !important;
                            padding-right: 5px;
                            font-family: $text-bold;

                            &:after {
                                font-family: $text-light;
                                @include font-size(xs);
                            }
                        }
                        .description {
                            @include font-size(xs);
                            font-family: $text-light;
                            line-height: 0.9rem !important;
                            color: #666 !important;
                            margin-top: 5px;
                            li {
                                color: #666 !important;
                                font-family: $text-light !important;
                            }
                        }

                        @include portrait-tablet {
                            .answer-title {
                                @include font-size(m);
                                line-height: 23px !important;
                            }

                            .description {
                                @include font-size(s);
                                line-height: 23px !important;
                            }
                        }
                    }
                    .response {
                        .unknown {
                            @include font-size(xs);
                        }
                        .value {
                        }
                    }
                }
            }

            .btn.action.toggleMeta {
                display: none !important;
            }

            .meta {
                min-height: auto !important;
                .info {
                    width: 100% !important;
                    min-height: unset;
                    .sign {
                        @include font-size(xs);
                        widows: 100% !important;
                        .name,
                        .bold,
                        .datetime {
                            @include font-size(xs);
                        }
                    }
                    .timing {
                        .line {
                            .name {
                                @include font-size(xs);
                            }
                        }
                    }
                }
                .actions {
                    width: unset;

                    button.btn.action {
                        @include font-size(0px);
                        background-color: $default-bg-color;
                        line-height: 16px;
                        background-size: 25px;
                        background-position: center center;
                        height: 45px;
                        width: 45px;
                        margin-left: 15px;
                        margin-top: 5px;

                        &:last-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
        .custom-alert-task {
            padding-left: 6px;
            .andy {
                left: 0px;
            }
        }
        &[noApply='1'] .answer {
            .line {
                width: 100%;
            }
            .statement {
                width: calc(100% - 150px);
                max-width: calc(100% - 150px);
            }
            .response {
                height: 100%;
                width: 150px;
            }
        }
    }
}
</style>