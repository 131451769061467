<template>
    <div v-if="categories" id="column" class="left column scrollbar" :class="[$mq]">
        <div v-for="item in categories" :key="item.id" class="category">
            <template v-if="item.highlight && showHighLight">
                <div class="item" :style="selected == item.id ? { backgroundColor: item.color ? item.color : '#666' } : ''" :class="{ selected: item.id == selected && !search }" @click="selectCategory(item)">
                    <span class="color" :style="{ backgroundColor: item.color ? item.color : '#000000' }"></span>
                    <span class="titl" :style="selected == item.id ? { color: '#fff' } : ''">{{ item.name }}</span>
                </div>
            </template>
            <template v-else-if="!item.highlight">
                <div class="item" @click="selectCategory(item)" :class="[{ expanded: isExpanded(item) }, { parent: item.subcategories && Object.keys(item.subcategories).length > 0 }, { selected: item.id == selected }]" :style="selected == item.id ? { backgroundColor: item.color ? item.color : '#666' } : {}">
                    <span class="color" :style="{ backgroundColor: item.color ? item.color : '#000000' }"></span>
                    <span class="titl">{{ item.name }}</span>
                </div>
                <div class="contain" v-if="item.subcategories && Object.keys(item.subcategories).length > 0" v-show="expanded.indexOf(item.id) > -1">
                    <div v-for="(section, j) in item.subcategories" :key="section.id" class="item child" :class="{ selected: section.id == selected && !search }" @click="selectCategory(section)">
                        <span class="titl" :style="selected == section.id && !search ? { backgroundColor: item.color ? item.color : '#666' } : {}">{{ section.name }}</span>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ColumnRegister',
    props: {
        gSelected: { type: [String, Number, Boolean, Object], default: false },
        search: { type: [String, Number, Boolean, Object], default: false },
        portraitSearchOpen: { type: [Boolean], default: false },
        showHighLight: { type: Boolean, default: true }
    },
    data() {
        return {
            expanded: [],
            selected: false
        }
    },
    computed: {
        categories() {
            var items = this.$store.getters['register/getCategories']()

            return Object.values(items).sort((a, b) => {
                if (a.order > b.order) {
                    return 1
                } else if (a.order < b.order) {
                    return -1
                } else {
                    return 0
                }
            })
        }
    },
    methods: {
        isExpanded(item) {
            if (item && (item.selected || this.expanded.indexOf(item.id) > -1)) {
                return true
            } else {
                return false
            }
        },

        selectCategory(item) {
            if (item) {
                if (!item.parent_id) {
                    if (this.expanded.includes(item.id)) {
                        this.expanded = this.expanded.filter(e => e !== item.id)
                    } else this.expanded.push(item.id)
                }
                this.selected = item.id
                this.$emit('changeCategory', item)
            }
        }
    },
    created() {
        if (!this.gSelected && !this.showHighLight && this.categories.length > 1) {
            var cat = this.categories[1]
            this.selectCategory(cat.subcategories[Object.keys(cat.subcategories)[0]])
            this.selected = this.gSelected.id
        } else {
            if (this.gSelected) {
                if (this.gSelected.parent_id) {
                    this.selectCategory(this.gSelected)
                    this.expanded.push(this.gSelected.parent_id)
                } else {
                    this.selectCategory(this.gSelected)
                    this.expanded.push(this.gSelected.id)
                }
            }
        }
    },
    watch: {
        gSelected: function() {
            // if (this.selected === false) {
            if (this.gSelected && this.categories) {
                if (!this.isExpanded(this.categories.find(category => category.id == this.gSelected.parent_id))) {
                    this.selectCategory(this.categories.find(category => category.id == this.gSelected.parent_id))
                }
                this.selected = this.gSelected.id
            } else if (!this.gSelected && !this.showHighLight && this.categories.length > 1) {
                var cat = this.categories[1]
                this.selectCategory(cat.subcategories[Object.keys(cat.subcategories)[0]])
                this.selected = this.gSelected.id
            }
            // }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#column.left {
    @include border-radius($alex-app-padding);
    @include box-shadow($h: 0px, $v: 0px, $b: 6px, $s: 0px, $c: rgba($neutro-s90, 0.2));
    padding: 0px 0px 9px 9px;

    &.column {
        height: calc(100%);
    }

    .category {
        $icon-size: 14px;

        margin: 9px 0;

        .item {
            @include border-radius(4px 0 0 4px);
            @include display-flex();
            @include align-items();
            padding: 0 0 0 6px;
            cursor: pointer;

            .color {
                @include border-radius(50%);
                display: inline-block;
                min-width: $icon-size;
                width: $icon-size;
                height: $icon-size;
            }

            .titl {
                @include border-radius(4px 0 0 4px);
                @include text-ellipsis($line: 2);
                @include display-inline-flex();
                @include align-items();
                @include font-size(m);
                font-family: $text-bold;
                color: $neutro-s90;
                min-height: 40px;
                height: auto;
                max-width: calc(100% - #{$icon-size});
                width: auto;
                padding: 6px 6px 6px 9px;
            }

            &.child {
                .titl {
                    @include interaction();
                    font-family: $text;
                    min-height: 40px;
                    width: 100%;
                    padding-left: 15px;
                    margin-left: $icon-size;
                    margin-bottom: 3px;

                    @include bgHover($inactive-t90);
                }
            }

            &.parent {
                @include interaction();

                .titl {
                    @include background($image: img('down_caret_neutro_s90.svg'), $size: 6px, $position: right center);
                    padding-right: 12px;
                }
                &.expanded {
                    .titl {
                        background-image: img('up_caret_neutro_s90.svg');
                    }
                }

                @include bgHover($inactive-t90);
            }
        }

        .item.selected {
            &.child .titl {
                font-family: $text-medium;
                color: #fff;
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
#column.column.portrait {
    .category {
        .item {
            border-radius: 3px;
            background-color: #fff;
            padding: 10px;
            cursor: pointer;

            .titl {
                width: 100%;
                background-size: 10px;
                background-position: right 5px center;
            }

            &.parent {
                .titl {
                    @include background($image: img('right_neutro.svg'), $size: 10px, $position: right center);
                    padding-right: 12px;
                }
            }

            &.selected {
                .titl {
                    color: $neutro-s90;
                }
            }
        }
    }
}
</style>
