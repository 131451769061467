<template>
    <div v-if="check" ref="container" id="detail-content" class="content scrollbar right" :class="[{ 'hide-scrollbar': ['portrait'].includes($mq) }, $mq]">
        <div class="top-container" :class="{ infoOpened: infoIsOpen }">
            <AppccTopDetail :hasMoreOptions="canDelete()" @infoOpened="infoOpened" @deleteButton="removeRegister()" @backButton="backToDetail()" :userInfo="check.employee" :title="check.title" :createdAt="check.created_date" :completed="check.complete_date"></AppccTopDetail>
        </div>
        <div class="template-sections" v-if="sections.length > 1">
            <Template-sections :sections="sections"></Template-sections>
        </div>
        <div class="detail-box" :class="[{ 'with-sections': sections.length > 1 }, { 'sidebar-collapsed': collapsedInfo }, { 'not-saved': !check.created_date }, { 'opened-info': infoIsOpen }]">
            <!-- <div class="top-info">
                <div class="content">
                    <span class="item-name">{{ check.title }}</span>
                    <span class="item-time">
                        <template v-if="check.complete_date">
                            <div class="hour">{{ $t('assets.timing.completed') }} {{ check.complete_date | moment('HH:mm') }}</div>
                            <div class="date">
                                {{ check.complete_date | moment('DD/MM/YYYY') }}
                            </div>
                        </template>
                    </span>
                </div>

                <div class="resume"></div>

                <div v-if="sections.length > 0" class="sidebar-sections hide-scrollbar">
                    <div class="control-overflow">
                        <p v-for="(section, index) in sections" class="item" :class="{ subtitle: section.type == 9 }" :key="index" @click="toSection(section.item_id)" v-if="section.type != 9">
                            {{ section.title }}
                        </p>
                    </div>
                </div>
            </div> -->

            <div class="exam performing">
                <div class="message" v-if="!check.complete_date">
                    <Message :message="$t('register.draft')" type="warning"></Message>
                </div>
                <div v-if="check.description" class="check-description">
                    <div class="message keep-format" v-html="descriptionParse(check.description)" @click="view_more = !view_more"></div>
                </div>

                <template v-if="check">
                    <answer v-for="answer in itemsSorted" :item="answer" :list="check" :cId="check.id" :aId="answer.item_id" :cType="type" :key="answer.item_id"></answer>
                </template>
            </div>
        </div>
    </div>
    <div v-else class="content hide-scrollbar right empty">
        <!-- <div class="message empty">
            {{ getString('register.empty') }}
        </div> -->
        <EmptyPage :buttonText="$t('empty_pages.return_logs')" @buttonAction="back()" buttonClass="back" :type="'error'" :title="$t('assets.overlay.timeout.message')" :description="$t('empty_pages.error_record')"></EmptyPage>
    </div>
</template>

<script>
import EmptyPage from '@/components/layout/EmptyPage'
import answer from '@/components/domain/tasks/answer'
import UserTag from '@/components/layout/UserTag'
import AppccTopDetail from '@/components/layout/AppccTopDetail'
import TemplateSections from '@/components/layout/TemplateSections'
import Message from '@/components/domain/ui/Message'
export default {
    components: { answer, UserTag, EmptyPage, AppccTopDetail, Message, TemplateSections },
    props: {
        gId: { type: String }
    },
    data() {
        return {
            collapsedInfo: false,
            type: 3,
            infoIsOpen: false,
            view_more: false
        }
    },
    computed: {
        check() {
            var check = this.$store.getters['register/getById'](this.gId)
            if (check == undefined) {
                this.$bar.hide()
            }
            return check
        },
        sections() {
            var sections = []
            for (var i in this.check.items) {
                if (this.check.def.item.itemsTitle.indexOf(this.check.items[i].type) != -1) {
                    sections.push(this.check.items[i])
                }
            }
            return sections
        },
        hasConnection() {
            return fnCheckConnection()
        },
        itemsSorted() {
            if (this.check.items) {
                return Object.values(this.check.items).sort((a, b) => {
                    return a.order - b.order
                })
            }
        },
        removeRolesLevel() {
            return this.$store.getters['login/getRemoveRolesLevel']
        },
        roles() {
            return this.$store.getters['employee/getAllRoles']
        },
        emp_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },
        userRole() {
            var user = this.$store.getters['employee/getEmployee'](this.emp_id)
            if (user && Object.keys(this.roles).length > 0) {
                return this.roles[user.rol]
            }
        }
    },
    methods: {
        removeRegister() {
            const employee_id = this.$store.getters['loginUser/getLocalEmployee']
            const template_id = this.$route.params.id
            var self = this
            this.$popup.delete({
                message: this.$t('register.delete_msg', { register: this.check.title }),
                textSave: this.$t('popup.delete.text_save'),
                textCancel: this.$t('popup.delete.text_cancel'),
                callSave: function() {
                    self.$overlay.loading()
                    self.$store
                        .dispatch('register/delete', { check: self.gId, emp: employee_id })
                        .then(response => {
                            self.$overlay.hide()
                            self.$popup.close()
                            self.$router.push({ name: 'RegisterDetail' }, { id: template_id })
                        })
                        .catch(error => {
                            logError(error)
                            self.$overlay.hide()
                            self.$popup.close()
                            self.$router.push({ name: 'RegisterDetail' }, { id: template_id })
                        })
                }
            })
        },

        canDelete() {
            // REGISTER IS ON DRAFT = TRUE
            if (this.check && this.check.status == 0) {
                return true
            }
            // IF HAS REMOVEROLESLEVEL CHECK IF USERROLE LEVEL IS
            else if (this.removeRolesLevel) {
                if (this.userRole && parseInt(this.userRole.level) >= parseInt(this.removeRolesLevel)) {
                    return true
                } else return false
            }
            // USER ROLE >= 2 = TRUE
            else if (this.userRole && parseInt(this.userRole.level) >= 2) {
                return true
            }
            // DEFAULT HIDE
            else return false
        },

        infoOpened() {
            this.infoIsOpen = !this.infoIsOpen
        },
        back() {
            this.$router.push({ name: 'Register' })
        },
        backToDetail() {
            var self = this
            // if (!this.hasConnection) {
            //     self.$popup.confirm({
            //         message: self.$t('register.cancel_uncomplete'),
            //         textSave: self.$t('register.cancel_close'),
            //         textCancel: self.$t('register.cancel_complete'),
            //         callCancel: function (popup, store, item) {
            //             if (item == 'button') {
            //                 self.$router.push({
            //                     name: 'RegisterDetail',
            //                     params: { id: self.$route.params.id }
            //                 })
            //             } else {
            //                 popup.callSave(popup, store)
            //             }
            //         }
            //     })
            // } else {
            self.$router.push({
                name: 'RegisterDetail',
                params: { id: self.$route.params.id, noRefresh: true }
            })
            // }
        },
        descriptionParse(msg) {
            if (msg.split(' ').length < 100) {
                return msgParse(msg)
            } else {
                if (this.view_more) {
                    return msgParse(msg) + ' <span class="more"> ' + this.$t('label.less') + '</span>'
                } else {
                    var maxLength = 150 // maximum number of characters to extract

                    var trimmedMsg = msg.substr(0, maxLength)

                    trimmedMsg = trimmedMsg.substr(0, Math.min(trimmedMsg.length, trimmedMsg.lastIndexOf(' ')))
                    return msgParse(trimmedMsg) + '... <span class="more">' + this.$t('label.more') + '</span>'
                }
            }
        },
        getString(str) {
            return this.$t(str)
        },
        getStringVariables(str, params) {
            return this.$t(str, params)
        },
        getStringPlural(str, pos) {
            return this.$tc(str, pos)
        },
        getStringVariable(str, pos) {
            return this.$t(str, { s: pos })
        },
        getStringPluralVariable(str, pos, text) {
            return this.$tc(str, pos, { s: text })
        },
        redirectPath(firstResult) {
            log(this.$root.redirectPath(firstResult))
            return this.$root.redirectPath(firstResult)
        }
    },
    filters: {
        moment: function(date, format) {
            return date ? moment(date).format(format) : ''
        }
    },
    mounted() {
        if (this.gId == 'add') {
            this.$overlay.loading()
        }
    },
    watch: {
        gId(newValue, oldValue) {
            this.$overlay.hide()
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.register.detail {
    padding: 0px;
    $column-width: 0%;
    $column-max-width: rem(0px);
    $content-width: 100%;

    > .content {
        $top-info: rem(70px);
        $resume-width: rem(100px);
        overflow: hidden !important;
        padding-top: 0;
        padding-bottom: 0 !important;
        width: calc(#{$content-width}) !important;
        min-width: calc(100% - #{$column-max-width}) !important;

        .detail-box {
            $sidebar-size: rem(40px);
            $collapse-button: rem(40px);
            $content-padding: rem(0px);
            $detail-bg: $default-bg-color;
            $detail-text-color: $main-dark;

            position: relative;
            padding: 0;
            padding-left: $content-padding;
            width: 100%;
            height: calc(100% - 185px);
            background-color: $detail-bg;
            overflow: hidden;

            &.with-sections {
                // padding-top: $top-info + $sidebar-size;
            }

            .message {
                padding-left: 10px;
            }

            .exam {
                @include border-radius($default-border-radius);
                height: 100%;
                overflow: auto;
                // min-height: 100%;
            }
        }

        .check-description {
            @include background($color: shade($default-bg-color, 4%), $image: img('alexhead_06.svg'), $position: left 12px center, $size: 50px);
            width: 100%;
            padding: 16px 20px 16px 80px;
            margin: 0 auto 0 auto;

            .alex {
                @include background($image: img('alexhead_04.svg'), $size: contain, $position: left bottom);
                width: 40px;
                height: 40px;
                display: inline-block;
                margin-right: 6px;
            }

            .message {
                @include font-size(sm);
                font-family: $text;
                color: $main-dark;
                display: inline-block;
                overflow: visible;
                width: auto;
                max-width: calc(100% - 40px - 6px);
                min-height: 20px;
                height: auto;

                .more {
                    color: $main-t20;
                    cursor: pointer;
                }
            }
        }
        .top-container {
            background-color: $default-bg-color;
            width: 100%;
            padding-top: 11px;
            height: auto;
            padding-bottom: 10px;
        }
        .template-sections {
            width: 100%;
            height: 50px;
        }
        &.desktop {
            .top-container {
                background-color: $default-bg-color;
                width: 100%;
                padding: 12px;
                padding-bottom: 10px;
                max-height: 106px;
            }
            // Calcular la altura de todo el contenido top que es fijo para el listado
            .detail-box {
                height: calc(100% - 120px);
                &.with-sections {
                    height: calc(100% - 155px);

                    &.opened-info {
                        height: calc(100% - 185px);
                    }
                }
                &.opened-info {
                    height: calc(100% - 135px);
                }
            }
        }
        &.landscape {
            .top-container {
                padding-left: 11px;
                padding-right: 11px;
            }
            // Calcular la altura de todo el contenido top que es fijo para el listado
            .detail-box {
                height: calc(100% - 115px);

                &.with-sections {
                    height: calc(100% - 160px);

                    &.opened-info {
                        height: calc(100% - 185px);
                    }
                }
                &.opened-info {
                    height: calc(100% - 130px);
                }
            }
        }
    }

    > .column.left {
        $content-space: 6px;
        width: $column-width !important;
        max-width: $column-max-width !important;
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
#content.portrait {
    .content.portrait {
        .detail-box {
            background: transparent;
            height: calc(100% - 63px);
            padding-bottom: 0px;

            .message {
                padding-left: 10px;
            }

            .exam {
                height: calc(100% - 63px);
                overflow: auto;

                .justify-description,
                .check-available {
                    padding: 8px 20px 8px 50px;
                    background-size: 25px;
                    background-position: left 10px center;

                    .message {
                        @include font-size(xs);
                        line-height: 1rem;
                        width: calc(100% - 100px);
                    }
                    .action {
                        width: 100px;
                        @include font-size(xs);
                        line-height: 0.75rem;
                    }
                }
                .check-available {
                    .message {
                        width: 100%;
                        max-width: 100%;
                    }
                }
                .check-description {
                    padding: 8px 20px 8px 50px;
                    background-size: 25px;
                    background-position: left 10px center;

                    .message {
                        @include font-size(xs);
                        line-height: 1rem;
                    }
                }
            }
            &.not-saved {
                height: calc(100% - 115px);
                padding-bottom: 0px;
            }
            &.opened-info {
                height: calc(100% - 115px);
                padding-bottom: 0px;
            }
            &.with-sections {
                height: calc(100% - 112px);
                padding-bottom: 0px;

                &.opened-info {
                    height: calc(100% - 162px);
                    padding-bottom: 0px;
                }
            }
        }
        .top-container {
            height: auto;
            max-height: 115px;
        }
    }
}
</style>
